
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useMutation, useQuery } from '@apollo/client'

import withModal from '../../hoc/withModal/withModal'
import { ALBUM_QUERY, UPDATE_ALBUM_MUTATION } from '../renameAlbum/RenameAlbum'
import { NEW_ALBUM_FRAGMENT } from '../createAlbum/CreateAlbum'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalFooter from '../modalFooter/ModalFooter'
import Button from '../button/Button'
import Spinner from '../spinner/Spinner'
import FormNavigation from '../formNavigation/FormNavigation'
import { 
  createItemRef,
  addItemToSortedByStringEdges,
  removeItemFromEdges,
} from '../../helpers/GraphqlHelpers'






function MoveAlbum(props) {
  const [originalFolder, setOriginalFolder] = useState(null)
  const [title, setTitle] = useState('')
  const [folder, setFolder] = useState(null)

  let id = props.modalOptions && props.modalOptions.albumID ?
    props.modalOptions.albumID : null

  const getAlbumStatus = useQuery(ALBUM_QUERY, {
    variables: { id },
    onCompleted: ({ node }) => {
      // We populate state with initial data
      const folderID = node.folder ? node.folder.id : null
      setTitle(node.title || '')
      setOriginalFolder(folderID)
      setFolder(folderID)
    }
  })

  const [moveAlbum, { loading }] = useMutation(UPDATE_ALBUM_MUTATION, {
    update: (cache, { data: { updateAlbum }}) => {
      const updatedAlbumRef = createItemRef(cache, updateAlbum, NEW_ALBUM_FRAGMENT)

      function addAlbumToCache(cachedAlbums, { readField }) {
        return addItemToSortedByStringEdges(cachedAlbums, readField,
          updatedAlbumRef, 'title')
      }

      function removeAlbumFromCache(cachedAlbums, { readField }) {
        return removeItemFromEdges(cachedAlbums, readField, updateAlbum.id)
      }
      
      // We remove album from old parent (user if it's root, folder otherwise)
      let originalFolderObject = originalFolder ? getAlbumStatus.data.node.folder :
        updateAlbum.user
      cache.modify({
        id: cache.identify(originalFolderObject),
        fields: {
          albums: removeAlbumFromCache,
        }
      })

      // We add album to new parent (user if it's root, folder otherwise)
      let parentObject = folder ? updateAlbum.folder : updateAlbum.user
      cache.modify({
        id: cache.identify(parentObject),
        fields: {
          albums: addAlbumToCache,
        }
      })
    },
    onCompleted: (data) => {
      //console.log('updated album', data)
      props.closeModal()
    },
    onError: (e) => console.error(e),
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    moveAlbum({ variables: {
      id, title, folder,
    }})
  }

  if (getAlbumStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getAlbumStatus.error) {
    console.error(getAlbumStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <p>Sorry, an error occured loading initial data...</p>
      </ModalSection>
    )
  }

  //console.log('MoveAlbum', props)
  return (
    <ModalSection>
      <ModalHeader
        title={"Move album to"}
        close={props.closeModal}
        closeTitle="Cancel"
      />
        <FormNavigation
          parentID={folder}
          setParentID={setFolder}
          editedID={id}
          disabled={folder === originalFolder}
        />
      <ModalFooter>
        {loading && <Spinner message="Moving album..." />}
        <Button
          primary={true}
          disabled={folder === originalFolder}
          onClick={handleSubmit}
        >Move album</Button>
      </ModalFooter>
    </ModalSection>
  )
}

MoveAlbum.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    albumID: PropTypes.string,
  }).isRequired,
}


export default withModal(MoveAlbum)


