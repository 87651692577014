
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  useQuery,
  gql,
} from '@apollo/client'

import { currentModuleVar } from '../headerSearch/HeaderSearch'

import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'
import NavigationBar from '../navigationBar/NavigationBar'
import FolderCreateButton from '../folderCreateButton/FolderCreateButton'
import FolderMenuButton from '../folderMenuButton/FolderMenuButton'
import FolderAbstract from '../folderAbstract/FolderAbstract'
import AlbumAbstract from '../albumAbstract/AlbumAbstract'




export const FOLDER_QUERY = gql`
  query FolderQuery($id: ID!) {
    node(id: $id) {
      id
      __typename
      ... on Folder {
        name
        parent {
          id
          name
        }
        folders {
          edges {
id
            name
          }
        }
        albums {
          edges {
            id
            title
            cover
          }
        }
      }
    }
  }
`

function FolderDetail({ folderID }) {
  // We set current module
  useEffect(() => {
    currentModuleVar(folderID)
  }, [folderID])
  
  const { loading, data, error } = useQuery(FOLDER_QUERY, {
    variables: { id: folderID },
  })
  
  if (loading) return <Spinner />
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }
  //console.log('FolderDetail', data)

  let backlink = "/"
  let backlinkTitle = "back to library"
  if (data.node.parent) {
    backlink = `/albums/${data.node.parent.id}/`
    backlinkTitle = `back to folder "${data.node.parent.name}"`
  }


  return (
    <section id="navigationContent">
      <NavigationBar
        title={data.node.name}
        backlink={backlink}
        backlinkTitle={backlinkTitle}
      >
          <FolderMenuButton folderID={folderID} />
          <FolderCreateButton folderID={folderID} />
      </NavigationBar>
      <section className="columned178">
        {data.node.folders.edges.map(folder =>
          <FolderAbstract
            key={folder.id}
            folderID={folder.id}
            title={folder.name}
          />
        )}
        {data.node.albums.edges.map(album =>
          <AlbumAbstract
            key={album.id}
            albumID={album.id}
            title={album.title}
            cover={album.cover}
          />
        )}
      </section>
    </section>
  )
}


FolderDetail.propTypes = {
  folderID: PropTypes.string.isRequired,
}

export default FolderDetail
