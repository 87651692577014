import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from "prop-types"
import { useState, useEffect, useCallback } from 'react'

import Image from '../image/Image'
import styles from './justifiedGridPhotosList.module.css'

function JustifiedGridPhotosList({ photos }) {

  //console.log('JustifiedGridPhotosList', photos)

  const [grid, setGrid] = useState({
    heights: [],
    tops: [],
    lefts: [],
    lastClientWidth: 0,
    gridHeight: 0,
  })

  const onResize = () => {
    //console.log('on resize')
    // we do nothing if window width didn't change
    //console.log('clientWidth', document.documentElement.clientWidth)
    if (document.documentElement.clientWidth === grid.lastClientWidth) return
    //console.log('some change, compute')
    computeGrid()
  }

  const computeGrid = useCallback(() => {
    //console.log('compute grid')

    const CANAL = 10
    const MAX_HEIGHT = 400

    let canalsWidth = 0
    let top = 0
    let lineWidth = 0
    let gridHeight = - CANAL
    let gridWidth = document.documentElement.clientWidth - 2*CANAL
    let photosCount = 0
    let lineFirstIndex = 0
    let heights = []
    let tops = []
    let lefts = []
    let photosWidth = []

    photos.forEach( (photo, index) => {
      let photoRatio = photo.node.width / photo.node.height
      let maxWidth = MAX_HEIGHT * photoRatio
      photosWidth[index] = maxWidth
      
      let last = (photos.length - 1) === index
      lineWidth += maxWidth
      photosCount ++
      canalsWidth = (photosCount - 1) * CANAL
      if ((lineWidth + canalsWidth) > gridWidth || last) {
        let ratio = (gridWidth - canalsWidth) / lineWidth
        let height = Math.round(MAX_HEIGHT * ratio)

        if (last && ratio > 1) { // we are on last line and it won't complete
          // we use same height and ratio as for previous line if we have one
          // else we use max height
          if (heights.length > 0) {
            height = heights[heights.length - 1]
          } else {
            height = MAX_HEIGHT
          }
          ratio = height / MAX_HEIGHT
        }

        let left = 0
        for (let i = lineFirstIndex; i <= index; i++) {
          heights[i] = height
          tops[i] = top
          lefts[i] = left
          left += Math.round( photosWidth[i] * ratio) + CANAL
        }
        gridHeight += CANAL + height
        top += height + CANAL
        lineFirstIndex = index + 1
        lineWidth = 0
        photosCount = 0
        canalsWidth = 0
      }
    })


    setGrid({
      heights: heights,
      tops: tops,
      lefts: lefts,
      lastClientWidth: document.documentElement.clientWidth,
      gridHeight: gridHeight,
    })
  }, [photos])



  useEffect(() => {
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  })


  useEffect(() => {
    computeGrid()
  }, [computeGrid])



  return (
    <div
      className={styles.justifiedLightbox}
      style={{ height: grid.gridHeight + "px"}}
    >
      {photos.map( (photo, index) => {
        return (
          <div
            className={styles.wrapper}
            key={photo.node.id}
            style={{
              height: grid.heights[index] + "px",
              top: grid.tops[index] + "px",
              left: grid.lefts[index] + "px",
            }}
            title={photo.node.originalName}
          ><Link
            to={`photo/${photo.node.id}/`}
          >
          <Image
            photo={photo.node}
            minHeight={400}
          />
        </Link>
        </div>
        )
      })}
    </div>
  )
}

export default JustifiedGridPhotosList


JustifiedGridPhotosList.propTypes = {
  photos: PropTypes.array.isRequired,
}
