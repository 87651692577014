
import { Link } from 'react-router-dom'

import AccessibilityText from '../accessibilityText/AccessibilityText'

import styles from '../allPhotosAbstract/allPhotosAbstract.module.css'

function FolderAbstract({ folderID, title }) {
  return (
    <div className={styles.abstract}>
      <Link
        to={`/albums/${folderID}/`}
        title={title}
        className={styles.folder}
      >
        <AccessibilityText text={`Folder: ${title}`} />
      </Link>
      <h6>{title}</h6>
    </div>
  )
}

export default FolderAbstract
