
import React, { useEffect } from 'react'
import {
  useQuery,
  gql,
} from '@apollo/client'
import { NetworkStatus } from '@apollo/client'

import { currentModuleVar } from '../headerSearch/HeaderSearch'

import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'
import NavigationBar from '../navigationBar/NavigationBar'
import FolderCreateButton from '../folderCreateButton/FolderCreateButton'
import AllPhotosAbstract from '../allPhotosAbstract/AllPhotosAbstract'
import FolderAbstract from '../folderAbstract/FolderAbstract'
import AlbumAbstract from '../albumAbstract/AlbumAbstract'


export const ROOT_CONTENT_QUERY = gql`
  query RootContentQuery {
    me {
      id
      folders {
        edges {
          id
          name
        }
      }
      albums {
        edges {
          id
          title
          cover
        }
      }
    }
  }
`

function Library(props) {
  // We set current module
  useEffect(() => {
    currentModuleVar('home')
  }, [])

  const { data, error, networkStatus } = useQuery(ROOT_CONTENT_QUERY)
  //console.log('Library', data, error)
  
  if (networkStatus === NetworkStatus.loading) return <Spinner />
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }
  return (
    <section id="navigationContent">
      <NavigationBar
        title="Library"
      ><FolderCreateButton folderID={null} /></NavigationBar>
      <section className="columned178">
        <AllPhotosAbstract />
        {data.me.folders.edges.map(folder =>
          <FolderAbstract
            key={folder.id}
            folderID={folder.id}
            title={folder.name}
          />
        )}
        {data.me.albums.edges.map(album =>
          <AlbumAbstract
            key={album.id}
            albumID={album.id}
            title={album.title}
            cover={album.cover}
          />
        )}
      </section>
    </section>
  )
}


export default Library
