import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeVar, useReactiveVar } from '@apollo/client'

import styles from './headerSearch.module.css'

export const filterVar = makeVar(null)
// For moment filtering is only active in all photos
export const filterModuleVar = makeVar('all')
export const currentModuleVar = makeVar(null)

function HeaderSearch() {
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const currentModule = useReactiveVar(currentModuleVar)

  useEffect(() => {
    // We reset filter when module change
    if (currentModule !== filterModuleVar()) {
      filterVar(null)
      setSearch('')
    }
  }, [currentModule])

  return (
    <form
      className={styles.search}
      onSubmit={(e) => {
        e.preventDefault()
        let url = '/all/'
        filterVar(search)
        navigate(url)
      }}
    >
      <label
        className={styles.searchLabel}
      >
        <span className={styles.magnifying} />
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search in All Photos"
        />
      </label>
    </form> 
  )
}

export default HeaderSearch
