
import React, { useState, useEffect } from 'react'
import {
  useQuery,
  gql,
  NetworkStatus,
  useReactiveVar,
} from '@apollo/client'

import { 
  currentModuleVar,
  filterVar,
} from '../headerSearch/HeaderSearch'
import { IMAGE_PHOTO_FIELDS } from '../image/fragments'

import PhotosList from '../photosList/PhotosList'
import ManagePhotosList from '../managePhotosList/ManagePhotosList'
import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'
import NavigationBar from '../navigationBar/NavigationBar'
import AllPhotosMenuButton from '../allPhotosMenuButton/AllPhotosMenuButton'

const ALL_PHOTOS_QUERY = gql`
  ${IMAGE_PHOTO_FIELDS}
  query AllPhotosQuery(
    $first: Int,
    $after: String,
    $search: String,
  ) {
    me {
      id
      photos(first: $first, after: $after, search: $search) {
        totalCount
        filteredCount
        edges {
          node {
            ...ImagePhotoFields
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`


function AllPhotos() {

  // We set current module
  useEffect(() => {
    currentModuleVar('all')
  }, [])

  const [ manage, setManage ] = useState(false)
  const filter = useReactiveVar(filterVar)

  const { data, error, fetchMore, networkStatus } = useQuery(ALL_PHOTOS_QUERY, {
    variables: { 
      first: 50,
      after: null,
      search: filter,
    },
    notifyOnNetworkStatusChange: true,
  })
  
  //console.log('AllPhotos', data, error)
  
  if (networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables) {
    return <Spinner />
  }

  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }


  let photos = data.me.photos


  return (
    <section id="navigationContent">
      { manage ?
        (
          <ManagePhotosList
            photos={photos.edges}
            orderable={false}
            done={() => setManage(false)}
          />
        ) : (
          <>
          <NavigationBar
            title="All Photos"
            count={photos.totalCount}
            filteredCount={photos.filteredCount}
            backlink="/"
            backlinkTitle="back to library"
          >
            <AllPhotosMenuButton
              setManage={setManage}
            />
          </NavigationBar>
          <PhotosList
            photos={photos.edges}
            hasNextPage={photos.pageInfo.hasNextPage}
            loadMore={() => fetchMore({
              variables: { 
                after: photos.pageInfo.endCursor,
              },
            })}
            loadingMore={networkStatus === NetworkStatus.fetchMore }
        /></>
        )
      }
    </section>
  )
}


export default AllPhotos
