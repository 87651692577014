import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import withModal from '../../hoc/withModal/withModal'


import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import Button from '../button/Button'
import Spinner from '../spinner/Spinner'


const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      message
    }
  }
`

function Logout(props) {
  const navigate = useNavigate()
  const [logout, { loading, error, client }] = useMutation(LOGOUT_MUTATION, {
    onCompleted: (data) => {
      // we reset store
      client.resetStore()
      props.closeModal()
      navigate('/')
    },
    onError: (e) => { console.error(e) },
  })


  if (error) {
    console.error(error)
    return (
      <ModalSection>
        <ModalHeader
          title="Sign out"
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured signing out...</p>
        </ModalContent>
      </ModalSection>
    )
  }


  if (loading) {
    return (
      <ModalSection>
        <ModalHeader
          title="Sign out"
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <Spinner message="Signing out..." />
        </ModalContent>
      </ModalSection>
    )
  }



  return (
    <ModalSection>
      <ModalHeader
        title="Sign out"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <h6>Are you sure you want to sign out?</h6>
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={logout}
          primary={true}
        >Sign out</Button>
      </ModalFooter>
    </ModalSection>
  )
}


Logout.propTypes = {
  closeModal: PropTypes.func.isRequired,
}




export default withModal(Logout)
