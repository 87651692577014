import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { listsHaveCommon } from '../../helpers/CommonHelpers'

import PhotoUploaderItem from '../photoUploaderItem/PhotoUploaderItem'
import Button from '../button/Button'

import styles from './photoUploader.module.css'

const VALID_DROPS = ["Files"]
const IMAGE_TYPE = /image.*/

const dropValid = (types) => {
  /*
   * Returns true if drag object is valid for basket
   */
  // we convert to a list because firefox convert types to a "DomStringList"
  // which mades everything fail
  types = Array.prototype.slice.call(types, 0)
  return listsHaveCommon(types, VALID_DROPS)
}



function PhotoUploader(props) {
  
  const [dragover, setDragover] = useState(false)

  const handleAddPictureClick = (e) => {
    /*
     * Emulate click on file input when button
     * is clicked to hide ugly input and open browser
     * file selection window
     */
    e.preventDefault()
    let input = document.getElementById('uploader-input')
    input.click()
  }

  const handleAddFiles = (filesToAdd) => {
    /*
     * Add given files to list
     */
    let newFiles = props.files.slice()
    for (let file of filesToAdd) {
      if (! file.type.match(IMAGE_TYPE)) continue
      file.addedAt = Date.now()
      newFiles.push(file)
      props.setFiles(newFiles)
    }
  }

  const handleRemoveFile = (index) => {
    /*
     * Remove given file from list
     */
    let newFiles = props.files.slice()
    newFiles.splice(index, 1)
    props.setFiles(newFiles)
  }

  const handleDrop = (e) => {
    if (dropValid(e.dataTransfer.types)) {
      e.preventDefault()
      setDragover(false)
      handleAddFiles(e.dataTransfer.files)
    }
  }

  const handleDragEnter = (e) => {
    if (dropValid(e.dataTransfer.types)) {
      e.preventDefault()
      setDragover(true)
    }
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setDragover(false)
  }

  const handleDragOver = (e) => {
    if (dropValid(e.dataTransfer.types)) {
      e.preventDefault()
      e.dataTransfer.dropEffect = "copy"
    }
  }

  const handleInputChange = (e) => {
    handleAddFiles(e.target.files)
  }


  const getClasses = () => {
    let classes = [styles.uploader]
    if (dragover) {
      classes.push(styles.dragover)
    }
    return classes.join(" ")
  }

  return (
        <div
          className={getClasses()}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          title="You can add picture's files here by drag and drop or clicking on 'Add pictures button', then deactivate the ones you don't want to upload and click 'upload' button."
        >
          <div className={styles.itemsWrapper}>
            {props.files.map((file, index) =>
              <PhotoUploaderItem
                file={file}
                index={index}
                remove={handleRemoveFile}
                key={file.name + file.addedAt}
              />
            )}
          </div>
          <p className={styles.helper}>Drag some pictures here or click "Add pictures" button.</p>
          <Button
            primary={true}
            onClick={handleAddPictureClick}
          >Add pictures</Button>
          <input
            id="uploader-input"
            type="file"
            multiple
            style={{display: "none"}}
            onChange={handleInputChange.bind(this)}
          />
        </div>
  )
}


PhotoUploader.propTypes = {
  setFiles: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
}


export default PhotoUploader


