import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import withModal from '../../hoc/withModal/withModal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import Button from '../button/Button'
import Spinner from '../spinner/Spinner'
import { 
  removeItemFromEdges,
} from '../../helpers/GraphqlHelpers'
import { FOLDER_QUERY } from '../renameFolder/RenameFolder'



const DELETE_FOLDER_MUTATION = gql`
mutation DeleteFolder($id: ID!) {
  deleteFolder(id: $id) {
    id
    parent { id }
    user { id }
  }
}
`

function DeleteFolder(props) {

  const navigate = useNavigate()
  
  let id = props.modalOptions && props.modalOptions.folderID ?
    props.modalOptions.folderID : null

  const getFolderStatus = useQuery(FOLDER_QUERY, {
    variables: { id },
  })

  const [deleteFolder, { loading, error }] = useMutation(DELETE_FOLDER_MUTATION, {
    variables: { id },
    update: (cache, { data: { deleteFolder }}) => {

      function removeFolderFromCache(cachedFolders, { readField }) {
        return removeItemFromEdges(cachedFolders, readField, deleteFolder.id)
      }

      // we get folder parent (user if it's root, other folder otherwise)
      let parent = deleteFolder.parent ? deleteFolder.parent : deleteFolder.user

      // We remove folder from it's parent
      cache.modify({
        id: cache.identify(parent),
        fields: {
          folders: removeFolderFromCache,
        }
      })
    },
    onCompleted: (data) => {
      let backlink = "/"
      if (data.deleteFolder.parent) {
        backlink = `/albums/${data.deleteFolder.parent.id}/`
      }
      //console.log('deleted folder', data)
      props.closeModal()
      // we redirect to parent
      navigate(backlink)
    },
    onError: (e) => console.error(e),
  })

  //console.log('DeleteFolder', props)

  if (getFolderStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getFolderStatus.error) {
    console.error(getFolderStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title="Delete a folder"
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured loading initial data...</p>
        </ModalContent>
      </ModalSection>
    )
  }  

  if (error) {
    console.error(getFolderStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title="Delete a folder"
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured deleting...</p>
        </ModalContent>
      </ModalSection>
    )
  }

  let data = getFolderStatus.data.node


  return (
    <ModalSection>
      <ModalHeader
        title="Delete a folder"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <h6>Are you sure you want to delete folder <em>"{data.name}"</em> ?</h6>
        <p>Its albums will also be deleted, but not photos they contain.</p>
        <p><em>(this operation is irreversible)</em></p>
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Deleting folder..." />}
        <Button
          onClick={deleteFolder}
          primary={true}
        >Delete folder</Button>
      </ModalFooter>
    </ModalSection>
  )
}



DeleteFolder.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}

export default withModal(DeleteFolder)



