import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import withModal from '../../hoc/withModal/withModal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import CreateFolderForm from '../createFolderForm/CreateFolderForm'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'
import { 
  sortEdges,
} from '../../helpers/GraphqlHelpers'

const CREATE_FOLDER_FORM = 'CREATE_FOLDER_FORM'

export const FOLDER_QUERY = gql`
query Folder($id: ID!) {
  node(id: $id) {
    __typename
... on Folder {
      id
      name
      parent { id }
      user { id }
    }
  }
}
`

export const UPDATE_FOLDER_MUTATION = gql`
mutation UpdateFolder(
  $id: ID!
  $name: String!,
  $parent: ID,
) {
  updateFolder(
    id: $id,
    name: $name,
    parent: $parent,
  ) {
    __typename
    id
    createdAt
    name
    user { id }
    parent { id }
  }
}
`

function RenameFolder(props) {
  const [originalName, setOriginalName] = useState('')
  const [name, setName] = useState('')
  const [parent, setParent] = useState(null)

  let id = props.modalOptions && props.modalOptions.folderID ?
    props.modalOptions.folderID : null

  const getFolderStatus = useQuery(FOLDER_QUERY, {
    variables: { id },
    onCompleted: ({ node }) => {
      // We populate state with initial data
      const parentID = node.parent ? node.parent.id : null
      setName(node.name || '')
      setOriginalName(node.name || '')
      setParent(parentID)
    }
  })

  const [renameFolder, { loading, error }] = useMutation(UPDATE_FOLDER_MUTATION, {
    update: (cache, { data: { updateFolder }}) => {
      // We getfolder folder parent (user if it's root, other folder otherwise)
      let parent = updateFolder.parent ? updateFolder.parent : updateFolder.user

      function sortFoldersInCache(cachedFolders, { readField }) {
        return sortEdges(cachedFolders, readField, 'name')
      }

      cache.modify({
        id: cache.identify(parent),
        fields: {
          folders: sortFoldersInCache,
        }
      })
    },
    onCompleted: (data) => {
      //console.log('updated folder', data)
      props.closeModal()
    },
    onError: (e) => console.error(e),
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    renameFolder({ variables: {
      id, name, parent,
    }})
  }

  if (getFolderStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getFolderStatus.error) {
    console.error(getFolderStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <p>Sorry, an error occured loading initial data...</p>
      </ModalSection>
    )
  }

  //console.log('RenameFolder', props)
  return (
    <ModalSection>
      <ModalHeader
        title={`Rename folder "${originalName}"`}
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <CreateFolderForm
          form={CREATE_FOLDER_FORM}
          onSubmit={handleSubmit}
          handleNameChange={(e) => setName(e.target.value)}
          name={name}
          error={error}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Renaming folder..." />}
        <Submit
          primary={true}
          form={CREATE_FOLDER_FORM}
          value={"Rename folder"}
        />
      </ModalFooter>
    </ModalSection>
  )
}

RenameFolder.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    folderID: PropTypes.string,
  }).isRequired,
}


export default withModal(RenameFolder)


