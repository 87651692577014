import PropTypes from 'prop-types'


import styles from './navigationMenu.module.css'


function NavigationMenu({ close, children }) {
  return (
    <div
      className={styles.menu}
    >{children}</div>
  )
}

NavigationMenu.propTypes = {
  children: PropTypes.element.isRequired,
}

export default NavigationMenu


