import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client'
import { relayStylePagination } from "@apollo/client/utilities"

import './styles/reset.css'
import './styles/fonts.css'
import './styles/index.css'
import './styles/forms.css'
import App from './components/app/App'

const REACT_APP_GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT

export const cache = new InMemoryCache({
  typePolicies: {
    FolderConnection: {
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming)
      }
    },
    AlbumConnection: {
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming)
      }
    },
    User: {
      fields: {
        photos: relayStylePagination(["search"]),
      }
    },
    Album: {
      fields: {
        photos: relayStylePagination(),
      }
    },
  }
})

const client = new ApolloClient({
  uri: REACT_APP_GRAPHQL_ENDPOINT,
  cache,
})





const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
