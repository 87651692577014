import PropTypes from 'prop-types'

import { gql, useMutation } from '@apollo/client'

import styles from './photoRate.module.css'
import AccessibilityText from '../accessibilityText/AccessibilityText'

const RATE_PHOTO_MUTATION = gql`
mutation RatePhoto($id: ID!, $rating: Int!) {
  ratePhoto(id: $id, rating: $rating) {
    __typename
    id
    rating
  }
}
`

function PhotoRateItem({ step, rating, setRate }) {
  let targetRate, className
  if (step > rating) {
    className = styles.point
    targetRate = step
  } else {
    className = styles.star
    targetRate = rating === step ?
    step - 1 : step
  }
  let star = targetRate === 1 ? 'star' : 'stars'
  let title = `${targetRate} ${star} rating`

  return (
    <button
      className={className}
      title={title}
      onClick={() => setRate(targetRate)}
    >
      <AccessibilityText text={title} />
    </button>
  )
}


function PhotoRate({ id, rating }) {
  const [ratePhoto] = useMutation(RATE_PHOTO_MUTATION, {
    onError: (e) => { console.error(e) },
  })

  let rateSteps = [1, 2, 3, 4, 5]

  let setRate = (targetRate) => {
    ratePhoto({variables: { 
      id,
      rating: targetRate,
    }})
  }

  return (
    <ul className={styles.rating}>
      {rateSteps.map(step => (
        <li
          key={step}
        >
          <PhotoRateItem
            step={step}
            rating={rating}
            setRate={setRate}
          />
        </li>
      ))}
    </ul>
  )
}

PhotoRate.propTypes = {
  id: PropTypes.string.isRequired,
  rating: PropTypes.number,
}


export default PhotoRate
