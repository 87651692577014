import PropTypes from 'prop-types'
import AccessibilityText from '../accessibilityText/AccessibilityText'
import styles from './deletePhotoButton.module.css'

import {
  modalTypes,
  modalStatusVar,
  modalOptionsVar,
} from '../../modal'

function DeletePhotosButton({
  active, getSelectedIDs, redirectTo, unselectAll
}) {
  return (
    <div className={styles.wrapper}>
      <button
        className={active ? styles.deleteButtonActive : styles.deleteButton}
        title="Delete selection from Photo"
        onClick={() => {
          if (! active) return
          modalStatusVar(modalTypes.DELETE_PHOTOS)
          modalOptionsVar({
            photosIDs: getSelectedIDs(),
            redirectTo,
            unselectAll,
          })
        }}
      >
        <AccessibilityText text="Delete selection from Photo" />
      </button>
    </div>
  )
}

export default DeletePhotosButton

DeletePhotosButton.propTypes = {
  active: PropTypes.bool.isRequired,
  getSelectedIDs: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  unselectAll: PropTypes.func,
}




