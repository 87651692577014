import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

import { FOLDER_QUERY } from '../folderDetail/FolderDetail'
import { ROOT_CONTENT_QUERY } from '../library/Library'

import AlbumCover from '../albumCover/AlbumCover'
import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'

import styles from './formNavigation.module.css'

function RootContent(props) {
  const { loading, data, error } = useQuery(ROOT_CONTENT_QUERY)
  
  if (loading) return <Spinner />
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }

  return (
    <Content
      name={'Library'}
      {...props}
      folders={data.me.folders.edges}
      albums={data.me.albums.edges}
    />
  )
}



function FolderContent(props) {
  const { loading, data, error } = useQuery(FOLDER_QUERY, {
    variables: { id: props.parentID },
  })
  
  if (loading) return <Spinner />
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }

  let backID = data.node.parent ? data.node.parent.id : null

  return (
    <Content
      {...props}
      back={true}
      backID={backID}
      name={data.node.name}
      folders={data.node.folders.edges}
      albums={data.node.albums.edges}
    />
  )
}



function Content({
  editedID,
  parentID,
  setParentID,
  albumID,
  setAlbumID,
  disabled,
  back,
  backID,
  name,
  folders,
  albums,
}) {
  return (
    <div className={styles.wrapper}>
      <nav className={disabled ? styles.disabledNavigation : styles.navigation}>
        {back ? (<Back 
            backID={backID}
            setParentID={setParentID} 
            setAlbumID={setAlbumID}
          />) : null}
        <h2>{name}</h2>
        {back ? (<div className={styles.spacer} />) : null}
      </nav>
      <ul className={styles.list}>
        {folders.map(folder =>
          <InlineFolder
            key={folder.id}
            folderID={folder.id}
            editedID={editedID}
            title={folder.name}
            setParentID={setParentID}
            setAlbumID={setAlbumID}
          />
        )}
        {albums.map(album =>
          <InlineAlbum
            key={album.id}
            albumID={album.id}
            editedID={editedID}
            setAlbumID={setAlbumID}
            title={album.title}
            selected={album.id === albumID}
            cover={album.cover}
          />
        )}
      </ul>
    </div>
  )
}


function Back({ backID, backTitle, setParentID, setAlbumID }) {
  return (
    <button
      className={styles.back}
      onClick={() => {
        setParentID(backID)
        if (setAlbumID) setAlbumID(null)
      }}
      title={backTitle}
  ></button>
  )
}

function InlineAlbum({ title, setAlbumID, albumID, editedID,
                     selected, cover }) {

  const image = cover ? (
    <AlbumCover
      coverID={cover}
      height={35}
      width={35}
      alt={`Album: ${title}`}
    />
  ) : null


  // We have no function, so we albums are disabled
  // or we try to add again photos in their current album
  if (! setAlbumID || editedID === albumID) { 
    return (
      <li className={styles.disabledAlbum}>
        <div className={styles.disabledAlbumIcon}>
          {image}
        </div>
        <h6>{title}</h6>
      </li>
    )
  }
  return (
    <li
      className={selected ?
        styles.selectedAlbum : 
        styles.album}
      onClick={() => selected ? setAlbumID(null) : setAlbumID(albumID)}
    >
      <div className={styles.albumIcon}>
        {image}
      </div>
      <h6>{title}</h6>
      { selected ? (<div className={styles.checkbox} />) : null}
    </li>
  )
}

function InlineFolder({ title, folderID, setParentID, setAlbumID, editedID }) {
  if (editedID === folderID) {
    // we are on current edited folder, can't go inside it
    return (
      <li
        className={styles.disabledFolder}
      >
        <div className={styles.disabledFolderIcon} />
        <h6>{title}</h6>
      </li>
    )
  }
  return (
    <li
      className={styles.folder}
      onClick={() => {
        setParentID(folderID)
        if (setAlbumID) setAlbumID(null)
      }}
    >
      <div className={styles.folderIcon} />
      <h6>{title}</h6>
    </li>
  )
}



function FormNavigation(props) {
  //console.log('FormNavigation', props)
  if (! props.parentID) {
    return (
      <RootContent
        {...props}
      />
    )
  }
  
  return (
    <FolderContent 
      {...props}
    />
  )
}


FormNavigation.propTypes = {
  // currently edited object (album or folder, can't be selected
  editedID: PropTypes.string, 
  // displayed folder
  parentID: PropTypes.string,
  // set displayed folder
  setParentID: PropTypes.func.isRequired,
  // selected album
  albumID: PropTypes.string,
  // set selected album
  setAlbumID: PropTypes.func,
  // displayed folder is disabled
  // (currently edited object folder can't be selected)
  disabled: PropTypes.bool,
}

export default FormNavigation

