import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  gql,
  useMutation,
  useLazyQuery,
  useApolloClient,
} from '@apollo/client'

import withModal from '../../hoc/withModal/withModal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalFooter from '../modalFooter/ModalFooter'
import Button from '../button/Button'
import Spinner from '../spinner/Spinner'
import FormNavigation from '../formNavigation/FormNavigation'
import { 
  createItemRef,
  addItemToReverseSortedByNumberEdges,
} from '../../helpers/GraphqlHelpers'


const ALBUM_MAX_ORDER_QUERY = gql`
  query AlbumMaxOrderQuery(
    $album: ID!,
    $first: Int,
    $after: String,
  ) {
    node(id: $album) {
      id
      __typename
      ... on Album {
        cover
        photos(first: $first, after: $after) {
          edges { order }
        }
      }
    }
  }
`


const ADD_PHOTO_TO_ALBUM_MUTATION = gql`
mutation AddPhotoToAlbum($photo: ID!, $album: ID!, $order: Int!) {
  addPhotoToAlbum(photo: $photo, album: $album, order: $order) {
    created
    albumPhotoEdge {
      __typename
      id
      cursor
      order
      node { id }
    }
  }
}
`

const NEW_ALBUM_PHOTO_EDGE_FRAGMENT = gql`
  fragment NewAlbumPhotoEdge on AlbumPhotoEdge {
    id
    cursor
    order
    node { id }
  }
`


function AddPhotosToAlbum(props) {
  const client = useApolloClient()
  const [album, setAlbum] = useState(null)
  const [parent, setParent] = useState(null)
  
  const [getAlbumMaxOrder] = useLazyQuery(ALBUM_MAX_ORDER_QUERY, {
    fetchPolicy: 'no-cache', // else it erases current photos in cache
    onError: (e) => console.error(e),
  })

  const [addPhotoToAlbum, { loading }] = useMutation(ADD_PHOTO_TO_ALBUM_MUTATION, {
    update: (cache, { data: { addPhotoToAlbum } }) => {

      //console.log('addPhotoToAlbum', addPhotoToAlbum)

      // We don't update cache if albumPhoto hasn't been created
      // (photo is already in album)
      if (! addPhotoToAlbum.created) return

      const newAlbumPhotoEdgeRef = createItemRef(
        cache, addPhotoToAlbum.albumPhotoEdge, NEW_ALBUM_PHOTO_EDGE_FRAGMENT
      )

      function addAlbumPhotoEdgeToCache(cachedEdges, { readField }) {
        return addItemToReverseSortedByNumberEdges(
          cachedEdges, readField, newAlbumPhotoEdgeRef, 'order'
        )
      }

      // we add new photo to album
      cache.modify({
        id: `Album:${album}`,
        fields: {
          photos: addAlbumPhotoEdgeToCache,
          // we delete single photos, because prev / next ids are not good anymore
          photo(cachedPhoto, { DELETE }) {
            // No idea why, it doesn't work with INVALIDATE sentinel.
            return DELETE
          }
        }
      })
    },
    onCompleted: (data) => {
      props.closeModal()
    },
    onError: (e) => console.error(e),
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { data } = await getAlbumMaxOrder({variables: { 
      album, first: 1, after: null,
    }})
    
    let IDs = props.modalOptions.photosIDs
  
    if (! data.node.cover) {
      //console.log('Cover not set, set it to', IDs[IDs.length -1])
      client.cache.writeQuery({
        query: gql`
          query SetAlbumCover($id: ID!) {
            node(id: $id) {
              id
              cover
            }
          }
        `,
        data: {
          node: {
            id: album,
            __typename: 'Album',
            cover: IDs[IDs.length -1],
          },
        },
        variables: {
          id: album,
        },
      })
      /* Doesn't work, no idea why.
       * Returns cache.modify is not a function
       * but modify is a function when console.log(cache)
       * works with cache.writeQuery (above)
      client.cache.modify({
        id: `Album:${album}`,
        fields: {
          cover: IDs[IDs.length -1]
        }
      })
      */
    }

    //console.log('data', data)

    let order
    if (data.node.photos.edges.length === 0) {
      order = 1
    } else {
      order = data.node.photos.edges[0].order + 1
    }


    for (let i = IDs.length -1; i >= 0; i--) {
      // We reverse loop on IDs for them to appear in same order
      // in album (newest firsts)
      const result = await addPhotoToAlbum({variables: {
        photo: IDs[i],
        album: album,
        order: order,
      }})

      //console.log('add2album result', result)
      // Increment order only if created
      if (result.data.addPhotoToAlbum.created) {
        order++
      }
    }
  }

  //console.log('Render AddPhotosToAlbum', props)
  return (
    <ModalSection>
      <ModalHeader
        title={"Add photos to"}
        close={props.closeModal}
        closeTitle="Cancel"
      />
        <FormNavigation
          parentID={parent}
          setParentID={setParent}
          albumID={album}
          setAlbumID={setAlbum}
          editedID={props.modalOptions.currentAlbumID}
        />
      <ModalFooter>
        {loading && <Spinner message="Adding photos..." />}
        <Button
          primary={true}
          disabled={! album}
          onClick={handleSubmit}
        >Add</Button>
      </ModalFooter>
    </ModalSection>
  )
}



AddPhotosToAlbum.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    currentAlbumID: PropTypes.string,
    photosIDs: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
  }).isRequired,
}


export default withModal(AddPhotosToAlbum)
