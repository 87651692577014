
// Delete a field in cache
export function deleteField(_, { DELETE }) {
  return DELETE
}

// Invalidate a field in cache
export function invalidateField(_, { INVALIDATE }) {
  return INVALIDATE
}

// Create a ref from item
export function createItemRef(cache, data, fragment) {
  return cache.writeFragment({
    data,
    fragment,
  })
}

// Create an edge from item ref
export function createItemEdgeNode(itemRef, typename) {
  return {
    __typename: typename,
    node: { itemRef }
  }
}


// Add an item to Edges list
export function addItemToEdges(cachedItems, newItemRef) {
  let edges
  if (! cachedItems.edges) {
    edges = [newItemRef]
  } else {
    edges = [newItemRef, ...cachedItems.edges]
  }
  return {
    ...cachedItems,
    totalCount: cachedItems.totalCount + 1,
    edges,
  }
}

function compareNames(a, b) {
  const nameA = a.toUpperCase()
  const nameB = b.toUpperCase()
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0 // a and b are equal
}

function sortStringAscThroughRef(refsArray, readField, sortKey) {
  refsArray.sort((refA, refB) => {
    const a = readField(sortKey, refA)
    const b = readField(sortKey, refB)
    return compareNames(a, b)
  })
}

// Add an item to sorted Edges list (sort by alphabetical order, asc)
export function addItemToSortedByStringEdges(cachedItems, readField, newItemRef, sortKey) {
  let edges
  if (! cachedItems.edges) {
    edges = [newItemRef]
  } else {
    edges = [newItemRef, ...cachedItems.edges]
    sortStringAscThroughRef(edges, readField, sortKey)
  }
  return {
    ...cachedItems,
    totalCount: cachedItems.totalCount + 1,
    edges,
  }
}

function sortNumberDescThroughRef(refsArray, readField, sortKey) {
  refsArray.sort((refA, refB) => {
    const a = readField(sortKey, refA)
    const b = readField(sortKey, refB)
    return b - a
  })
}

// Add an item to reverse sorted Edges list (sort by int, desc)
export function addItemToReverseSortedByNumberEdges(cachedItems, readField, newItemRef, sortKey) {
  let edges
  if (! cachedItems.edges) {
    edges = [newItemRef]
  } else {
    edges = [newItemRef, ...cachedItems.edges]
    sortNumberDescThroughRef(edges, readField, sortKey)
  }
  return {
    ...cachedItems,
    totalCount: cachedItems.totalCount + 1,
    edges,
  }
}


// Sort items in Edges list
export function sortEdges(cachedItems, readField, sortKey) {
  let edges = cachedItems.edges.slice()
  sortStringAscThroughRef(edges, readField, sortKey)

  return {
    ...cachedItems,
    edges,
  }
}


// Remove an item from Edges list
export function removeItemFromEdges(cachedItems, readField, idToRemove) {
  if (! cachedItems.edges) return
  return {
    ...cachedItems,
    totalCount: cachedItems.totalCount - 1,
    edges: cachedItems.edges.filter(
      itemRef =>  idToRemove !== readField('id', itemRef)
    )
  }
}


// Add node item to Edges list
export function addNodeToEdges(cachedItems, itemRef, typename) {
  return addItemToEdges(cachedItems, createItemEdgeNode(itemRef, typename))
}

// Remove node item from Edges list
export function removeNodeFromEdges(cachedItems, readField, idToRemove) {
  if (! cachedItems.edges) return
  return {
    ...cachedItems,
    totalCount: cachedItems.totalCount - 1,
    edges: cachedItems.edges.filter(
      itemRef =>  idToRemove !== readField('id', itemRef.node)
    )
  }
}
