import PropTypes from 'prop-types'

function getPhotoMaxSide(photo, minWidth, minHeight) {
  let ratio = photo.width / photo.height
  let landscape = ratio >=1
  let minSide = null
  let sizes = photo.previewsSizes.slice()
  let maxSide = sizes[sizes.length -1] // we default to biggest

  if (minHeight && minWidth && landscape) { // landscape with both width and height constraints
    minSide = minHeight * ratio > minWidth ?
      minHeight * ratio : minWidth
  }
  else if (! minHeight && landscape) { // landscape with minWidth constraint
    minSide = minWidth
  }
  else if (! minWidth && landscape) { // landscape with minHeight
    minSide = minHeight * ratio
  }
  else if (minHeight && minWidth) { // portrait with box width and height constraints
    minSide = minWidth / ratio > minHeight ?
      minWidth / ratio : minHeight
  }
  else if (minWidth) { // portrait with minWidth constraint
    minSide = minWidth / ratio
  }
  else if (! minWidth) { // portrait with minHeight constraint
    minSide = minHeight
  }

  for (let size of sizes) {
    if (size >= minSide) {
      maxSide = size
      break
    }
  }

  return maxSide
}

function getPhotoSrcSet(photo, format) {
  const ratio = photo.width / photo.height
  let widths

  // PreviewsSizes stores biggest side, so we extract widths
  if (ratio >= 1) {
    widths = photo.previewsSizes.slice()
  } else {
    widths = photo.previewsSizes.map(size => Math.round(size * ratio))
  }

  /*
   * We use 1x 1.5x 2x, so index, index +1 and index +2 for fixed layout
   * We add 0.25x and 0.5x, so index -1 and index -2, for fluid layout
   *
   * was not working, 0.25x isn't necessary index -2.
   * Instead give all sizes to browser, as they are already generated, and let it choose the best
   * no need to maintain a fluid / fixed layout prop too.
   */

  const sources = widths.map((width, index) =>
      `${getPhotoSrc(photo, photo.previewsSizes[index], format)} ${width}w`
  )

  return sources.join(', ')
}

function getPhotoSizes(photo, minWidth, minHeight) {
  const ratio = photo.width / photo.height
  let targetWidth = minWidth || minHeight * ratio

  return `(min-width: ${targetWidth}px) ${targetWidth}px, 100vw`
}

function getPhotoSecret(photo, size) {
  /* Small secret is good up to 2048 size */
  if (size <= 2048) return photo.smallSecret
  return photo.largeSecret
}

function getPhotoSrc(photo, size, format) {
  /*
   * Images urls construction
   *
   * "/media/{storageId}/previews/{sha1[0:2]}/{sha1[2:4]}/{sha1}_{secret}_{size}.{format}
   * "/media/{storageId}/originals/{sha1[0:2]}/{sha1[2:4]}/{sha1}_{secret}_original.{format}
   */

  const dir1 = photo.sha1.substring(0, 2)
  const dir2 = photo.sha1.substring(2, 4)
  const secret = getPhotoSecret(photo, size)
  return `/media/${photo.storageId}/previews/${dir1}/${dir2}/${photo.sha1}_${secret}_${size}.${format}`
}



function getPhotoIntrinsicSizes(photo, maxSide) {
  let ratio = photo.width / photo.height
  if (ratio >=1) {
    return {
      width: maxSide, 
      height: Math.round(maxSide / ratio),
    }
  }
  return {
    width: Math.round(maxSide * ratio),
    height: maxSide,
  }
}


function Image({ 
  photo,
  minWidth,
  minHeight,
  className,
  alt,
  title,
}) {

  //console.log('Image', photo, layout, minWidth, minHeight, className, alt, title)

  const maxSide = getPhotoMaxSide(photo, minWidth, minHeight)
  const src = getPhotoSrc(photo, maxSide, photo.previewsFormats[0])
  const sizes = getPhotoSizes(photo, minWidth, minHeight)
  const intrinsicSizes = getPhotoIntrinsicSizes(photo, maxSide)

  return (
    <picture>
      {photo.previewsFormats.map(format => {
        /* we don't return jpeg here else chrome choose it over webp */
        if (format === "jpeg") return null

        return (<source
          key={format}
          srcSet={getPhotoSrcSet(photo, format)}
          sizes={sizes}
          type={`image/${format}`}
        />)
      })}
      <img
        className={className}
        src={src}
        srcSet={getPhotoSrcSet(photo, photo.previewsFormats[0])}
        sizes={sizes}
        alt={alt || photo.title || photo.originalName}
        title={title || photo.title}
        height={intrinsicSizes.height}
        width={intrinsicSizes.width}
        style={{ backgroundColor: photo.dominantColor }}
      />
    </picture>
  )
}

Image.propTypes = {
  photo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    originalName: PropTypes.string.isRequired,
    title: PropTypes.string,
    sha1: PropTypes.string.isRequired,
    storageId: PropTypes.string.isRequired,
    smallSecret: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    dominantColor: PropTypes.string,
    previewsFormats: PropTypes.arrayOf(PropTypes.string).isRequired,
    previewsSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  className: PropTypes.string,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  alt: PropTypes.string,
  title: PropTypes.string,
}

export default Image
