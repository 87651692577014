
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Image from '../image/Image'

import styles from './managePhotosItem.module.css'

//import { PHOTO_DRAG_TYPE } from '../managePhotosList/ManagePhotosList'


function ManagePhotosItem(props) {
  //console.log('ManagePhotosItem', props)
  const [basket, setBasket] = useState(false)
  const [basketRightOver, setBasketRightOver] = useState(false)
  const [basketLeftOver, setBasketLeftOver] = useState(false)

  const handleClick = (e) => {
    e.stopPropagation()
    props.handleClick(props.index, e.ctrlKey || e.metaKey, e.shiftKey)
  }

  const handleDragEnter = (e) => {
    if (props.dropValid(e.dataTransfer.types) &&
      props.dragIndex !== props.index // no basket if dragged item
    ) {
      e.preventDefault()
      setBasket(true)
    }
  }

  const handleBasketLeave = (e) => {
    e.preventDefault()
    setBasket(false)
    setBasketRightOver(false)
    setBasketLeftOver(false)
  }

  const handleBasketRightEnter = (e) => {
    e.preventDefault()
    setBasketRightOver(true)
  }

  const handleBasketLeftEnter = (e) => {
    e.preventDefault()
    setBasketLeftOver(true)
  }

  const handleBasketOver = (e) => {
    if (props.dropValid(e.dataTransfer.types)) {
      e.preventDefault()
      e.dataTransfer.dropEffect = "move"
    }
  }

  const handleBasketRightDrop = (e) => {
    if (props.dropValid(e.dataTransfer.types)) {
      e.preventDefault()
      e.stopPropagation() // to avoid parent drop to trigger
      props.handleDrop(e, props.index + 1)
      handleBasketLeave(e) // reset styles
    }
  }

  const handleBasketLeftDrop = (e) => {
    if (props.dropValid(e.dataTransfer.types)) {
      e.preventDefault()
      e.stopPropagation() // to avoid parent drop to trigger
      props.handleDrop(e, props.index)
      handleBasketLeave(e) // reset styles
    }
  }

  const getWrapperClassName = () => {
    if (props.dragIndex === props.index) {
      // item is dragged, change style
      return styles.photoWrapper + ' ' + styles.dragPin
    }
    if (basketRightOver) {
      return styles.photoWrapper + ' ' + styles.photoRightOver
    }
    if (basketLeftOver) {
      return styles.photoWrapper + ' ' + styles.photoLeftOver
    }
    return styles.photoWrapper
  }

  const getPhotoClassName = () => {
    if (props.selected) return styles.photo + ' ' + styles.selected
    return styles.photo
  }

  

  return (
    <div
      className={getWrapperClassName()}
      onClick={props.unselectAll}
    >
      <article
        title={`${props.name} - Drag me!`}
        className={getPhotoClassName()}
        onClick={handleClick}
        onDragStart={(e) => props.handleDrag(e, props.index)}
        onDragEnter={handleDragEnter}
        onDragEnd={props.handleDragend}
        draggable="true"
      >
        <div
          className={styles.imgWrapper}
        >
          <Image
            photo={props.photo}
            minHeight={216}
            minWidth={216}
            draggable="false"
          />
        </div>
      </article>
      {basket ? (
        <div>
          <div
            className={basketLeftOver ? styles.basketLeftOver : styles.basketLeft}
            onDragLeave={handleBasketLeave}
            onDragEnter={handleBasketLeftEnter}
            onDragOver={handleBasketOver}
            onDrop={handleBasketLeftDrop}
          />
          <div
            className={basketRightOver ? styles.basketRightOver : styles.basketRight}
            onDragLeave={handleBasketLeave}
            onDragEnter={handleBasketRightEnter}
            onDragOver={handleBasketOver}
            onDrop={handleBasketRightDrop}
          />
        </div>) : null }
    </div>
  )
}


ManagePhotosItem.propTypes = {
  photo: PropTypes.object.isRequired,
  name: PropTypes.string,
  index: PropTypes.number.isRequired,
  handleDrag: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  handleDragend: PropTypes.func.isRequired,
  dropValid: PropTypes.func.isRequired,
  dragIndex: PropTypes.number,
  selected: PropTypes.bool,
  unselectAll: PropTypes.func.isRequired,
}

export default ManagePhotosItem
