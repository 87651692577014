import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ManagePhotosItem from '../managePhotosItem/ManagePhotosItem'
import ManagePhotosBar from '../managePhotosBar/ManagePhotosBar'
import DeletePhotosButton from '../deletePhotosButton/DeletePhotosButton'
import AddToAlbumButton from '../addToAlbumButton/AddToAlbumButton'
import RemoveFromAlbumButton from '../removeFromAlbumButton/RemoveFromAlbumButton'

export const PHOTO_DRAG_TYPE = "photo/photo"


const ACCEPTED_DROP = PHOTO_DRAG_TYPE


function ManagePhotosList({ photos, done, currentID, orderPhotos }) {
  //console.log('ManagePhotosList', photos, orderphotos, selected)
  
  const [selected, setSelected] = useState([])
  const [dragIndex, setDragIndex] = useState(null)

  const getSelectedIDs = () => {
    // We sort them to keep same order when
    // we copy them to an album, or when we
    // sort them by drag & drop
    const selecteds = selected.slice()
    selecteds.sort((a, b) => a - b)
    return selecteds.map(s => photos[s].node.id)
  }

  const handleClick = (index, modKey, shiftKey) => {
    /*
     * Classical selection system :
     *  - on simple click image is selected and others
     *    are not anymore
     *  - on ctrl click image selection is toogled
     *  - on shift click first selected item and clicked
     *    item interval is selected
     */

    // if ctrl or cmd key was pressed, toggle photos selection
    if (modKey) {
      if (selected.includes(index)) {
        let s = selected.slice()
        s.splice(selected.indexOf(index), 1)
        return setSelected(s)
        //return setSelected([...selected].splice(selected.indexOf(index), 1))
      }
      return setSelected([index, ...selected])
    }
    // if shift key was pressed, and at least one photo is already selected,
    // select all photos between selected and clicked
    if (shiftKey && selected.length > 0) {
      // get first selected index
      let i = Math.min(...selected)
      let startIndex, endIndex
      if (i < index) {
        startIndex = i
        endIndex = index
      }
      if (i > index) {
        startIndex = index
        endIndex = i
      }
      // if we didn't click on selected picture
      if (i !== index) {
        let s = []
        for (let i=startIndex; i <= endIndex; i++) {
          s.push(i)
        }
        return setSelected(s)
      }
    }
    // if no modkey, we select only clicked photo
    setSelected([index])
  }

  const unselectAll = () => {
    setSelected([])
  }

  const handleDrag = (e, index) => {
    if (! orderPhotos) return // list is not orderable
    /*
     * On drag start, store action targets
     * and drag type in state
     */
    e.persist()
    e.dataTransfer.setData(PHOTO_DRAG_TYPE, index)
    e.dataTransfer.dropEffect = "move"

    /* We store index in state because it can't be read
     * from dataTranfer on over effect
     */
    setDragIndex(index)
  }

  const handleDrop = (e, dropIndex) => {
    if (! orderPhotos) return // list is not orderable
    let newPhotos = photos.slice()
    const selectedIDs = getSelectedIDs()

    let dragged
    
    if (selected.includes(dragIndex) && selected.length > 1) {
      // dragged picture is selected, we move all selection
      dragged = selected.map((index) => photos[index])
      let s = selected.slice().sort().reverse()
      for (let i=0; i<s.length; i++) {
        // We remove selected items from list, starting by biggest index
        newPhotos.splice(s[i], 1)
      }
    } else {
      dragged = [photos[dragIndex]]
      // remove draged item from list
      newPhotos.splice(dragIndex, 1)
    }
    
    if (dragIndex < dropIndex) dropIndex = dropIndex - 1
    // add draged photo to new position
    newPhotos.splice(dropIndex, 0, ...dragged)
    
    let newSelection = []
    for (let i=0; i<newPhotos.length; i++) {
      if (selectedIDs.includes(newPhotos[i].node.id)) {
        newSelection.push(i)
      }
    }
    setSelected(newSelection)
    orderPhotos(newPhotos)
  }

  const handleDragend = () => {
    setDragIndex(null)
  }

  const dropValid = (types) => {
    /*
     * We convert to a list because firefox convert types to a 
     * "DomStringList" which made everything fail
     */
    types = Array.prototype.slice.call(types, 0)
    return types.includes(ACCEPTED_DROP)
  }




  return (
    <section>
      <ManagePhotosBar
        done={done}
        selected={selected}
      >
        <AddToAlbumButton
          active={selected.length > 0}
          getSelectedIDs={getSelectedIDs}
          currentAlbumID={currentID}
        />
        <RemoveFromAlbumButton
          active={selected.length > 0}
          getSelectedIDs={getSelectedIDs}
          currentAlbumID={currentID}
          unselectAll={unselectAll}
        />
        <DeletePhotosButton
          active={selected.length > 0}
          getSelectedIDs={getSelectedIDs}
          unselectAll={unselectAll}
        />
      </ManagePhotosBar>
      <div
        // unselect all on background click
        onClick={unselectAll}
        // extend div on all viewport height for 
        // background click to work until bottom of screen
        style={{ minHeight: 'calc(100vh - 88px)' }}
      ><div className={"columned250"}>
      {photos.map((photo, index) =>
        (<ManagePhotosItem
          key={photo.node.id}
          name={photo.node.originalName}
          photo={photo.node}
          index={index}
          handleClick={handleClick}
          handleDrag={handleDrag}
          handleDrop={handleDrop}
          handleDragend={handleDragend}
          dropValid={dropValid}
          dragIndex={dragIndex}
          selected={selected.includes(index)}
          unselectAll={unselectAll}
        />)
      )}
    </div></div>
    </section>
  )
}

export default ManagePhotosList

ManagePhotosList.propTypes = {
  photos: PropTypes.array.isRequired,
  done: PropTypes.func.isRequired,
  currentID: PropTypes.string,
  orderPhotos: PropTypes.func,
}
