import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'
import withModal from '../../hoc/withModal/withModal'
import { AUTHENTICATED_QUERY } from '../app/App'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import Button from '../button/Button'
import Spinner from '../spinner/Spinner'
import { 
  removeItemFromEdges,
  removeNodeFromEdges,
} from '../../helpers/GraphqlHelpers'



const DELETE_PHOTO_MUTATION = gql`
mutation DeletePhoto($photo: ID!) {
  deletePhoto(id: $photo) {
    albumsIDs
    albumPhotoEdgesIDs
    node {
      id
    }
  }
}
`

function DeletePhotos(props) {
  const navigate = useNavigate()

  const getUserStatus = useQuery(AUTHENTICATED_QUERY)
  const { photosIDs, redirectTo, unselectAll } = props.modalOptions

  const [deletePhoto, { loading }] = useMutation(DELETE_PHOTO_MUTATION, {
    update: (cache, { data: { deletePhoto }}) => {
      
      // Remove photo from user's allPhotos
      cache.modify({
        id: cache.identify(getUserStatus.data.me),
        fields: {
          photos: (cachedEdges, { readField }) => 
            removeNodeFromEdges(cachedEdges, readField, deletePhoto.node.id),
        }
      })
      
      // Remove photo from its albums
      for (const albumID of deletePhoto.albumsIDs) {
        cache.modify({
          id: `Album:${albumID}`,
          fields: {
            photos: (cachedEdges, { readField }) => 
              removeItemFromEdges(cachedEdges, readField, deletePhoto.node.id),
            cover(existingCover, { DELETE }) {
              if (existingCover === deletePhoto.node.id) {
                return DELETE
              }
            }
          }
        })
      }

      // Delete unreachable AlbumPhotoEdges from cache
      for (const edgeID of deletePhoto.albumPhotoEdgesIDs) {
        cache.evict({ id: `AlbumPhotoEdge:${edgeID}`})
      }

      // Remove photo from library cache
      cache.evict({ id: cache.identify(deletePhoto.node) })
      
    },
    onCompleted: (data) => {
      //console.log('Removed from album', data)
      props.closeModal()
    },
    onError: (e) => console.error(e),
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    for (let i=0; i < photosIDs.length; i++) {
      deletePhoto({variables: {
        photo: photosIDs[i],
      }})
    }
    if (unselectAll) unselectAll()
    if (redirectTo) {
      navigate(redirectTo)
    }
  }

  if (getUserStatus.loading) {
    return (
      <ModalSection max={true}>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getUserStatus.error) {
    console.error(getUserStatus.error)
    return (
      <ModalSection max={true}>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <p>Sorry, an error occured loading initial data...</p>
      </ModalSection>
    )
  }


  return (
    <ModalSection>
      <ModalHeader
        title="Delete photo"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <h6>Are you sure you want to permanently delete {photosIDs.length} photo{photosIDs.length > 1 ? "s" : ""}?</h6>
        {photosIDs.length > 1 ? 
            (<p><em>Those photos will be permanently deleted from all albums and from library".</em></p>)
          : 
            (<p><em>This photo will be permanently deleted from all albums and from library".</em></p>)
        }
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Deleting photos..." />}
        <Button
          onClick={handleSubmit}
          primary={true}
        >Delete</Button>
      </ModalFooter>
    </ModalSection>
  )
}

DeletePhotos.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    photosIDs: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
    redirectTo: PropTypes.string,
    unselectAll: PropTypes.func,
  })
}

export default withModal(DeletePhotos)
