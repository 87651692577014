import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation } from '@apollo/client'

import withModal from '../../hoc/withModal/withModal'


import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import CreateAlbumForm from '../createAlbumForm/CreateAlbumForm'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'
import { 
  createItemRef,
  addItemToEdges,
} from '../../helpers/GraphqlHelpers'

const CREATE_ALBUM_FORM = 'CREATE_ALBUM_FORM'

const CREATE_ALBUM_MUTATION = gql`
mutation AddAlbum(
  $title: String!,
  $folder: ID,
) {
  addAlbum(
    title: $title,
    folder: $folder,
  ) {
    __typename
    id
    createdAt
    title
    folder { id }
    user { id }
  }
}
`

export const NEW_ALBUM_FRAGMENT = gql`
  fragment NewAlbum on Album {
    id
    createdAt
    title
    folder
    user
  }
`


function CreateAlbum(props) {
  const [title, setTitle] = useState('')

  const [createAlbum, { loading, error }] = useMutation(CREATE_ALBUM_MUTATION, {
    update: (cache, { data: { addAlbum } }) => {

      const newAlbumRef = createItemRef(cache, addAlbum, NEW_ALBUM_FRAGMENT)
      
      function addAlbumToCache(cachedAlbums) {
        return addItemToEdges(cachedAlbums, newAlbumRef) 
      }

      // We add new album to user root albums if no folder
      if (! addAlbum.folder) {
        cache.modify({
          id: cache.identify(addAlbum.user),
          fields: {
            albums: addAlbumToCache,
          }
        })
      }

      // If we have a folder, we add new album to folder albums
      if (addAlbum.folder) {
        cache.modify({
          id: cache.identify(addAlbum.folder),
          fields: {
            albums: addAlbumToCache,
          }
        })
      }
    },
    onCompleted: (data) => {
      //console.log('created album', data)
      props.closeModal()
    },
    onError: (e) => { console.error(e) },
  })


  const handleSubmit = (e) => {
    e.preventDefault()
    createAlbum({ variables: {
      title, folder: props.modalOptions.folderID
    }})
  }

  //console.log('CreateAlbum', props)
  return (
    <ModalSection>
      <ModalHeader
        title={"Create an album"}
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <CreateAlbumForm
          form={CREATE_ALBUM_FORM}
          onSubmit={handleSubmit}
          handleTitleChange={(e) => setTitle(e.target.value)}
          title={title}
          error={error}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Creating album..." />}
        <Submit
          primary={true}
          form={CREATE_ALBUM_FORM}
          value={"Create album"}
        />
      </ModalFooter>
    </ModalSection>
  )
}

CreateAlbum.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    folderID: PropTypes.string,
  }).isRequired,
}



export default withModal(CreateAlbum)


