import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import withModal from '../../hoc/withModal/withModal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import CreateAlbumForm from '../createAlbumForm/CreateAlbumForm'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'
import { 
  sortEdges,
} from '../../helpers/GraphqlHelpers'

const CREATE_ALBUM_FORM = 'CREATE_ALBUM_FORM'

export const ALBUM_QUERY = gql`
query Album($id: ID!) {
  node(id: $id) {
    __typename
... on Album {
      id
      title
      folder { id }
      user { id }
    }
  }
}
`

export const UPDATE_ALBUM_MUTATION = gql`
mutation UpdateAlbum(
  $id: ID!
  $title: String!,
  $folder: ID,
) {
  updateAlbum(
    id: $id,
    title: $title,
    folder: $folder,
  ) {
    __typename
    id
    createdAt
    title
    user { id }
    folder { id }
  }
}
`

function RenameAlbum(props) {
  const [originalTitle, setOriginalTitle] = useState('')
  const [title, setTitle] = useState('')
  const [folder, setFolder] = useState(null)

  let id = props.modalOptions && props.modalOptions.albumID ?
    props.modalOptions.albumID : null

  const getAlbumStatus = useQuery(ALBUM_QUERY, {
    variables: { id },
    onCompleted: ({ node }) => {
      // We populate state with initial data
      const folderID = node.folder ? node.folder.id : null
      setTitle(node.title || '')
      setOriginalTitle(node.title || '')
      setFolder(folderID)
    }
  })

  const [renameAlbum, { loading, error }] = useMutation(UPDATE_ALBUM_MUTATION, {
    update: (cache, { data: { updateAlbum }}) => {
      // we get parent folder (user if it's root, folder otherwise)
      let folder = updateAlbum.folder ? updateAlbum.folder : updateAlbum.user

      function sortAlbumsInCache(cachedAlbums, { readField }) {
        return sortEdges(cachedAlbums, readField, 'title')
      }

      cache.modify({
        id: cache.identify(folder),
        fields: {
          albums: sortAlbumsInCache,
        }
      })
    },
    onCompleted: (data) => {
      //console.log('updated folder', data)
      props.closeModal()
    },
    onError: (e) => console.error(e),
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    renameAlbum({ variables: {
      id, title, folder,
    }})
  }

  if (getAlbumStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getAlbumStatus.error) {
    console.error(getAlbumStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <p>Sorry, an error occured loading initial data...</p>
      </ModalSection>
    )
  }

  //console.log('RenameAlbum', props)
  return (
    <ModalSection>
      <ModalHeader
        title={`Rename album "${originalTitle}"`}
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <CreateAlbumForm
          form={CREATE_ALBUM_FORM}
          onSubmit={handleSubmit}
          handleTitleChange={(e) => setTitle(e.target.value)}
          title={title}
          error={error}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Renaming album..." />}
        <Submit
          primary={true}
          form={CREATE_ALBUM_FORM}
          value={"Rename album"}
        />
      </ModalFooter>
    </ModalSection>
  )
}

RenameAlbum.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    albumID: PropTypes.string,
  }).isRequired,
}


export default withModal(RenameAlbum)


