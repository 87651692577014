import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery, useApolloClient } from '@apollo/client'

import withModal from '../../hoc/withModal/withModal'
import { AUTHENTICATED_QUERY } from '../app/App'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import PhotoUploader from '../photoUploader/PhotoUploader'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'
import { 
  createItemRef,
  addNodeToEdges,
} from '../../helpers/GraphqlHelpers'

const REACT_APP_UPLOAD_ENDPOINT = process.env.REACT_APP_UPLOAD_ENDPOINT


const UPLOAD_PHOTOS_FORM = 'UPLOAD_PHOTOS_FORM'


export const NEW_PHOTO_FRAGMENT = gql`
  fragment NewPhoto on Photo {
    id
    createdAt
    sha1
    smallSecret
    largeSecret
    originalSecret
    originalName
    title
    caption
    rating
    format
    weight
    height
    width
    previewsSizes
    previewsFormats
    camera
    lens
    focalLength
    flash
    exposureTime
    aperture
    iso
    exposureMode
    software
    exifDate
    exifOriginDate
  }
`


function UploadPhotos(props) {
  const client = useApolloClient()

  const [files, setFiles] = useState([])
  const [loadingMessage, setLoadingMessage] = useState(null)

  const getUserStatus = useQuery(AUTHENTICATED_QUERY)


  const handleSubmit = async (e) => {
    e.preventDefault()

    //console.log('submit!')
    let n = 1
    for (let file of files) {
      setLoadingMessage(`Uploading file ${n} of ${files.length}...`)
      const fd = new FormData()
      fd.append('photo', file)

      try {
        const response = await fetch(REACT_APP_UPLOAD_ENDPOINT + '/photo', {
          method: 'POST',
          body: fd,
        })
        const data = await response.json()

        if (data.photo) { // photo is not a clone, we add it to cache
          
          const newPhotoRef = createItemRef(client.cache, data.photo, NEW_PHOTO_FRAGMENT)

          function addPhotoToCache(cachedPhotos) {
            return addNodeToEdges(cachedPhotos, newPhotoRef, "PhotoEdge")
          }

          client.cache.modify({
            id: client.cache.identify(getUserStatus.data.me),
            fields: {
              photos: addPhotoToCache
            }
          })
        }
          
        //console.log('uploaded photo', data)
      } catch(e) {
        setLoadingMessage(null)
        console.error(e)
      }
      n++
    }
    props.closeModal()
  }

  if (getUserStatus.loading) {
    return (
      <ModalSection max={true}>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getUserStatus.error) {
    console.error(getUserStatus.error)
    return (
      <ModalSection max={true}>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <p>Sorry, an error occured loading initial data...</p>
      </ModalSection>
    )
  }

  //console.log('UploadPhoto', props)
  return (
    <ModalSection max={true}>
      <ModalHeader
        title="Upload photos from your computer"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <form
          id={UPLOAD_PHOTOS_FORM}
          onSubmit={handleSubmit}
        >
          <PhotoUploader
            files={files}
            setFiles={setFiles}
          />
        </form>
      </ModalContent>
      <ModalFooter>
        {loadingMessage && <Spinner message={loadingMessage} />}
        <Submit
          primary={true}
          form={UPLOAD_PHOTOS_FORM}
          value="Upload"
        />
      </ModalFooter>
    </ModalSection>
  )
}

UploadPhotos.propTypes = {
  closeModal: PropTypes.func.isRequired,
}


export default withModal(UploadPhotos)





