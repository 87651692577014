
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'


function FormAutoSaveTextInput({ submit, name, label, initialValue, className, placeholder }) {
  
  const [value, setValue] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    setValue(initialValue || '')
  }, [initialValue])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (value === initialValue) return
    submit(value)
    inputRef.current.blur()
  }

  const handleReset = () => {
    setValue(initialValue || '')
    inputRef.current.blur()
  }

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      handleReset()
    }
  }

  return (
    <form
      className={className}
      onSubmit={handleSubmit}
    >
      <label htmlFor={`id-${name}`}>{label}</label>
      <input
        id={`id-${name}`}
        name={name}
        type="text"
        value={value}
        placeholder={placeholder || ''}
        onBlur={handleSubmit}
        onChange={e => setValue(e.target.value)}
        onKeyDown={e => handleKeyDown(e)}
        ref={inputRef}
      />
    </form>
  )
}

FormAutoSaveTextInput.propTypes = {
  submit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
}


export default FormAutoSaveTextInput
