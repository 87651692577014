import styles from './modalSection.module.css'
import PropTypes from 'prop-types'

function ModalSection(props) {
  return (
    <div className={styles.modalMarginWrapper}>
      <section
        className={props.max ? styles.maxModalSection : styles.modalSection}
      >
        {props.children}
      </section>
    </div>
  )
}

ModalSection.propTypes = {
  max: PropTypes.bool,
}

export default ModalSection
