
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  gql,
  useQuery,
} from '@apollo/client'

import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'
import AccessibilityText from '../accessibilityText/AccessibilityText'
import AlbumCover from '../albumCover/AlbumCover'

import styles from './photoAlbums.module.css'

const PHOTO_ALBUMS_QUERY = gql`
  query PhotoAlbumsQuery($photoID: ID!) {
    node(id: $photoID) {
      id
      __typename
      ... on Photo {
        albums {
          edges {
            id
            title
            cover
          }
        }
      }
    }
  }
`


function PhotoAlbums({ photoID }) {
  const { loading, data, error } = useQuery(PHOTO_ALBUMS_QUERY, {
    variables: { photoID: photoID },
  })

  if (loading) return <Spinner />
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }

  //console.log('PhotoAlbums', data)

  return (
    <ul className={styles.albums}>
      {data.node.albums.edges.map(album =>
        <li
          key={album.id}
          className={styles.album}
        >
          <Link
            to={`/albums/${album.id}/`}
            title={album.title}
            alt={`Album: ${album.title}`}
          >{album.cover ? (
            <AlbumCover
              coverID={album.cover}
              height={100}
              width={100}
              title={album.title}
              alt={`Album: ${album.title}`}
            />
          ) : null}
            <AccessibilityText text={`Album: ${album.title}`} />
          </Link>
        </li>
      )}
    </ul>
  )
}

PhotoAlbums.propTypes = {
  photoID: PropTypes.string.isRequired,
}


export default PhotoAlbums
