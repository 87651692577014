import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import {
  useQuery,
  useMutation,
  useApolloClient,
  gql,
} from '@apollo/client'
import { NetworkStatus } from '@apollo/client'

import { IMAGE_PHOTO_FIELDS } from '../image/fragments'
import { currentModuleVar } from '../headerSearch/HeaderSearch'

import PhotosList from '../photosList/PhotosList'
import ManagePhotosList from '../managePhotosList/ManagePhotosList'
import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'
import NavigationBar from '../navigationBar/NavigationBar'
import AlbumMenuButton from '../albumMenuButton/AlbumMenuButton'



const ALBUM_QUERY = gql`
  ${IMAGE_PHOTO_FIELDS}
  query AlbumQuery(
    $id: ID!,
    $first: Int,
    $after: String,
  ) {
    node(id: $id) {
      id
      __typename
      ... on Album {
        title
        folder {
          id
          name
        }
        photos(first: $first, after: $after) {
          totalCount
          edges {
            id
            cursor
            order
            node {
              ...ImagePhotoFields
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`

const ORDER_PHOTO_MUTATION = gql`
mutation OrderPhotoInAlbum($photo: ID!, $album: ID!, $order: Int!) {
  orderPhotoInAlbum(photo: $photo, album: $album, order: $order) {
    __typename
    id
    cursor
    order
  }
}

`

function AlbumDetail({ albumID }) {
  // We set current module
  useEffect(() => {
    currentModuleVar(albumID)
  }, [albumID])

  const [ manage, setManage ] = useState(false)
  const [orderedPhotos, setOrderedPhotos ] = useState([])
  const client = useApolloClient()
  
  const { data, error, fetchMore, networkStatus } = useQuery(ALBUM_QUERY, {
    variables: {
      id: albumID,
      first: 50,
      after: null,
    },
    notifyOnNetworkStatusChange: true,
  })

  const [orderPhoto] = useMutation(ORDER_PHOTO_MUTATION, {
    onError: (e) => console.error(e)
  })
  
  if (networkStatus === NetworkStatus.loading) return <Spinner />
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }
  //console.log('AlbumDetail', data)

  let backlink = "/"
  let backlinkTitle = "back to library"
  if (data.node.folder) {
    backlink = `/albums/${data.node.folder.id}/`
    backlinkTitle = `back to folder "${data.node.folder.name}"`
  }
  let photos = data.node.photos

  const handleManageDone = () => {
    // grab smallest order number
    const minOrder = Math.min(...orderedPhotos.map(photo => photo.order))
    // highest order is first in array, so we start from highest
    let order = minOrder + (orderedPhotos.length - 1) 
    for (let photo of orderedPhotos) {
      if (photo.node.order !== order) {
        orderPhoto({variables: {
          photo: photo.node.id,
          album: albumID,
          order: order,
        }})
      }
      order--
    }
    // update cache
    client.cache.modify({
      id: client.cache.identify(data.node),
      fields: {
        // We reorder photos in cache
        photos(cachedPhotos, { readField }) {
          let orderedIDs = orderedPhotos.map(photo => photo.id)
          let edges = []
          for (let photoRef of cachedPhotos.edges) {
            let index = orderedIDs.indexOf(readField('id', photoRef))
            edges[index] = photoRef
          }
          return {
            ...cachedPhotos,
            edges
          }
        },
        // we delete single photos, because prev / next ids are not good anymore
        photo(cachedPhoto, { DELETE }) {
          // No idea why, it doesn't work with INVALIDATE sentinel.
          return DELETE
        }
      }
    })
    setManage(false)
  }


  return (
    <section id="navigationContent">
      { manage ?
        (
          <ManagePhotosList
            photos={orderedPhotos}
            orderable={true}
            done={handleManageDone}
            currentID={data.node.id}
            orderPhotos={setOrderedPhotos}
          />
        ) : (
          <>
          <NavigationBar
            title={data.node.title}
            count={photos.totalCount}
            backlink={backlink}
            backlinkTitle={backlinkTitle}
          >
            <AlbumMenuButton
              albumID={data.node.id}
              setManage={() => {
                setOrderedPhotos(photos.edges.slice())
                setManage(true)
              }}
            />
          </NavigationBar>
          <PhotosList
            photos={photos.edges}
            hasNextPage={photos.pageInfo.hasNextPage}
            loadMore={() => {
              return fetchMore({
                variables: { 
                  after: photos.pageInfo.endCursor,
                },
              })
            }}
            loadingMore={networkStatus === NetworkStatus.fetchMore }
          /></>
        )
      }
    </section>
  )
}


AlbumDetail.propTypes = {
  albumID: PropTypes.string.isRequired,
}

export default AlbumDetail



