import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation } from '@apollo/client'

import withModal from '../../hoc/withModal/withModal'


import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import CreateFolderForm from '../createFolderForm/CreateFolderForm'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'
import { 
  createItemRef,
  addItemToSortedByStringEdges,
} from '../../helpers/GraphqlHelpers'

const CREATE_FOLDER_FORM = 'CREATE_FOLDER_FORM'

const CREATE_FOLDER_MUTATION = gql`
mutation AddFolder(
  $name: String!,
  $parent: ID,
) {
  addFolder(
    name: $name,
    parent: $parent,
  ) {
    __typename
    id
    createdAt
    name
    parent { id }
    user { id }
  }
}
`

export const NEW_FOLDER_FRAGMENT = gql`
  fragment NewFolder on Folder {
    id
    createdAt
    name
    parent
    user
  }
`


function CreateFolder(props) {
  const [name, setName] = useState('')

  const [createFolder, { loading, error }] = useMutation(CREATE_FOLDER_MUTATION, {
    update: (cache, { data: { addFolder } }) => {

      const newFolderRef = createItemRef(cache, addFolder, NEW_FOLDER_FRAGMENT)
      
      function addFolderToCache(cachedFolders, { readField }) {
        return addItemToSortedByStringEdges(cachedFolders, readField, newFolderRef, 'name') 
      }

      // We add folder to its parent (user if it's root, other folder otherwise)
      let parent = addFolder.parent ? addFolder.parent : addFolder.user
      cache.modify({
        id: cache.identify(parent),
        fields: {
          folders: addFolderToCache,
        }
      })
    },
    onCompleted: (data) => {
      //console.log('created folder', data)
      props.closeModal()
    },
    onError: (e) => { console.error(e) },
  })


  const handleSubmit = (e) => {
    e.preventDefault()
    createFolder({ variables: {
      name, parent: props.modalOptions.folderID
    }})
  }

  //console.log('CreateFolder', props)
  return (
    <ModalSection>
      <ModalHeader
        title={"Create a folder"}
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <CreateFolderForm
          form={CREATE_FOLDER_FORM}
          onSubmit={handleSubmit}
          handleNameChange={(e) => setName(e.target.value)}
          name={name}
          error={error}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Creating folder..." />}
        <Submit
          primary={true}
          form={CREATE_FOLDER_FORM}
          value={"Create folder"}
        />
      </ModalFooter>
    </ModalSection>
  )
}

CreateFolder.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    folderID: PropTypes.string,
  }).isRequired,
}



export default withModal(CreateFolder)


