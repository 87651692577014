
import { Link } from 'react-router-dom'

import AccessibilityText from '../accessibilityText/AccessibilityText'

import styles from './allPhotosAbstract.module.css'

function AllPhotosAbstract() {
  return (
    <div className={styles.abstract}>
      <Link
        to={'/all/'}
        title={'All Photos'}
        className={styles.allPhotos}
      >
        <AccessibilityText text="All Photos" />
      </Link>
      <h6>All Photos</h6>
    </div>
  )
}

export default AllPhotosAbstract
