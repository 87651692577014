import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import AccessibilityText from '../accessibilityText/AccessibilityText'
import AlbumCover from '../albumCover/AlbumCover'

import styles from '../allPhotosAbstract/allPhotosAbstract.module.css'

function AlbumAbstract({ albumID, title, cover }) {

  //console.log('AlbumAbstract', albumID, title, cover)

  const ALT=`Album: ${title}`
  return (
    <div className={styles.abstract}>
      <Link
        to={`/albums/${albumID}/`}
        title={title}
        className={styles.album}
        alt={ALT}
      >
        {cover ? (
          <AlbumCover
            coverID={cover}
            height={150}
            width={150}
            title={title}
            alt={`Album: ${title}`}
          />
        ) : null}
        <AccessibilityText text={ALT} />
      </Link>
      <h6>{title}</h6>
    </div>
  )
}

AlbumAbstract.propTypes = {
  albumID: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cover: PropTypes.string,
}

export default AlbumAbstract
