import PropTypes from 'prop-types'

import { gql, useMutation } from '@apollo/client'

import AccessibilityText from '../accessibilityText/AccessibilityText'
import styles from './albumCoverButton.module.css'

const UPDATE_ALBUM_COVER_MUTATION = gql`
mutation UpdateAlbumCover($id: ID!, $cover: ID!) {
  updateAlbumCover(id: $id, cover: $cover) {
    __typename
    id
    cover
  }
}
`

function AlbumCoverButton({ currentAlbumID, currentPhotoID }) {
  
  const [updateAlbumCover] = useMutation(UPDATE_ALBUM_COVER_MUTATION)

  if (! currentAlbumID) return null
  return (
    <div className={styles.wrapper}>
      <button
        className={styles.coverButton}
        title="Set current photo as album's cover"
        onClick={() => { 
          updateAlbumCover({ variables: {
            id: currentAlbumID,
            cover: currentPhotoID,
          }})
        }}
      >
        <AccessibilityText text="Set current photo as album's cover" />
      </button>
    </div>
  )

}

export default AlbumCoverButton

AlbumCoverButton.propTypes = {
  currentAlbumID: PropTypes.string,
  currentPhotoID: PropTypes.string.isRequired,
}


