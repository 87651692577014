
import { Link } from 'react-router-dom'
import HeaderLinks from '../headerLinks/HeaderLinks'
import HeaderSearch from '../headerSearch/HeaderSearch'

import photo from './photo.min.svg'
import logo from './logo.png'
import styles from './header.module.css'


function Header() {
  return (
    <header
      role="banner"
      className={styles.header}
    ><h1
        className={styles.title}
  ><Link to="/">
      <img src={logo} alt="PhotoApp logo" className={styles.logo} />
      <img src={photo} alt="PhotoApp" className={styles.photo} />
    </Link></h1>
    <HeaderSearch />
      
      <HeaderLinks />

    </header>
  )
}

export default Header
