
import { useEffect } from 'react'

function useKeyboardNavigation(next, prev, close) {


  const handleKeyPress = (e) => {
    if (e.target.localName === 'input') return
    // navigate to next photo
    if (e.key === " " || e.key === "ArrowRight") {
      next(e)
    }

    // navigate to prev photo
    if (e.key === "Backspace" || e.key === "ArrowLeft") {
      prev(e)
    }

    // close lightbox
    if (e.key === "Escape") {
      close(e)
    }

    // prevent bubbling
    e.preventDefault()
    e.stopPropagation()
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)

    return () => window.removeEventListener('keydown', handleKeyPress)
  })
}

export default useKeyboardNavigation
