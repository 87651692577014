
import React, { useState, useEffect } from 'react'
import {
  useQuery,
  gql,
  useReactiveVar,
} from '@apollo/client'
import { 
  Link, 
  useParams, 
  useNavigate 
} from 'react-router-dom'

import { filterVar } from '../headerSearch/HeaderSearch'
import { IMAGE_PHOTO_FIELDS } from '../image/fragments'

import useKeyboardNavigation from '../../hooks/useKeyboardNavigation'

import Image from '../image/Image'
import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'
import AccessibilityText from '../accessibilityText/AccessibilityText'

import styles from './lightbox.module.css'

const ALBUM_PHOTO_QUERY = gql`
  ${IMAGE_PHOTO_FIELDS}
  query AlbumPhotoDetailQuery(
    $photoID: ID!,
    $albumID: ID!,
  ) {
    node(id: $albumID) {
      id
      __typename
      ... on Album {
        photo(id: $photoID) {
          nextID
          prevID
          node {
            ...ImagePhotoFields
          }
        }
      }
    }
  }
`

const USER_PHOTO_QUERY = gql`
  ${IMAGE_PHOTO_FIELDS}
  query UserPhotoQuery($photoID: ID!, $search: String) {
    me {
      id
      photo(id: $photoID, search: $search) {
        nextID
        prevID
        node {
          ...ImagePhotoFields
        }
      }
    }
  }
`

  
function Lightbox() {

  const [isFullscreen, setIsFullscreen] = useState(Boolean(document.fullscreenElement))

  useEffect(() => {
    function handleFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement))
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)
    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange)
  }, [])
  

  const navigateNext = () => {
    if (edge.nextID) {
      navigate(getLightboxLink(edge.nextID))
    }    
  }

  const navigatePrev = () => {
    if (edge.prevID) {
      navigate(getLightboxLink(edge.prevID))
    }
  }

  const closeLightbox = (e) => {
    e.preventDefault()
    return navigate(getBaseLink(photoID))
  }

  const navigate = useNavigate()
  const filter = useReactiveVar(filterVar)
  useKeyboardNavigation(navigateNext, navigatePrev, closeLightbox)
  
  let { photoID, albumID } = useParams()
  

  const QUERY = albumID ? ALBUM_PHOTO_QUERY : USER_PHOTO_QUERY


  const { loading, data, error } = useQuery(QUERY, {
    variables: {
      photoID,
      albumID,
      search: filter,
    },
  })
  
  if (loading) return <div className={styles.lightbox}><Spinner /></div>
  
  if (error) {
    return <ErrorMessage error={error} />
  }

  let edge = albumID ? data.node.photo : data.me.photo

  const getBaseLink = (photoID) => {
    if (albumID) {
      return `/albums/${albumID}/photo/${photoID}/`
    }
    return `/all/photo/${photoID}/`
  }

  const getLightboxLink = (photoID) => {
    return getBaseLink(photoID) + 'lightbox/'
  }

  //console.log('lightbox', data)
  //console.log('edge', edge)

  return (
    <div className={styles.lightbox}>
      <div className={styles.imageWrapper}>
        <Image
          photo={edge.node}
          className={styles.image}
          minWidth={2048}
        />
      </div>
      {edge.prevID ? (<Link
        className={styles.prev}
        to={getLightboxLink(edge.prevID)}
        title="Previous Photo"
      ><AccessibilityText text="Previous photo" /></Link>) : null}
      {edge.nextID ? (<Link
        className={styles.next}
        to={getLightboxLink(edge.nextID)}
        title="Next Photo"
      ><AccessibilityText text="Next photo" /></Link>) : null}
      { !isFullscreen ? (
        <Link
          className={styles.close}
          to={getBaseLink(photoID)}
          title="Close lightbox"
        ><AccessibilityText text="Close lightbox" /></Link>
      ) : null}
      { isFullscreen ? (
        <div
          className={styles.exitFullScreen}
          title="Exit full screen"
          onClick={() => {
            if (document.exitFullscreen) {
              document.exitFullscreen()
            }
          }}
        ><AccessibilityText text="Exit full screen" /></div>
      ) : (
        <div
          className={styles.fullScreen}
          title="Enter full screen"
          onClick={() => {
            if (!document.fullscreenElement) {
              document.documentElement.requestFullscreen()
            }
          }}
        ><AccessibilityText text="Full screen" /></div>
    )}
    </div>
  )
}

export default Lightbox
