import PropTypes from 'prop-types'
import React, { useState } from 'react'

import AccessibilityText from '../accessibilityText/AccessibilityText'
import NavigationMenu from '../navigationMenu/NavigationMenu'

import styles from '../folderMenuButton/folderMenuButton.module.css'

import {
  modalTypes,
  modalStatusVar,
  modalOptionsVar,
} from '../../modal'

function AlbumMenuButton({ albumID, setManage }) {
  const [ menu, setMenu ] = useState(false)
  function getMenu() {
    if (! menu) return null
    return (
      <NavigationMenu>
        <ul>
          <li><button
              onClick={() => {
              setManage()          
              setMenu(false)
            }}
          >Select photos</button></li>
          <hr />
          <li><button
            onClick={() => {
              modalStatusVar(modalTypes.MOVE_ALBUM)
              modalOptionsVar({ albumID })
              setMenu(false)
            }}
          >Move album</button></li>
          <li><button
            onClick={() => {
              modalStatusVar(modalTypes.RENAME_ALBUM)
              modalOptionsVar({ albumID })
              setMenu(false)
            }}
          >Rename album</button></li>
          <hr />
          <li><button
            onClick={() => {
              modalStatusVar(modalTypes.DELETE_ALBUM)
              modalOptionsVar({ albumID })
              setMenu(false)
            }}
          >Delete album</button></li>
        </ul>
      </NavigationMenu>
    )
  }
  
  return (
    <div className={styles.wrapper}>
      <button
        className={styles.menuButton}
        onClick={() => {
          setMenu(! menu)
        }}
      >
          <AccessibilityText text="Album menu" />
      </button>
      {getMenu()}
    </div>
  )
}

export default AlbumMenuButton

AlbumMenuButton.propTypes = {
  albumID: PropTypes.string,
  setManage: PropTypes.func.isRequired,
}


