import PropTypes from 'prop-types'

import FormRequiredField from '../formRequiredField/FormRequiredField'
import FormRequiredFields from '../formRequiredFields/FormRequiredFields'
import FieldWrapper from '../fieldWrapper/FieldWrapper'
import FormErrors from '../formErrors/FormErrors'

function CreateFolderForm(props) {
  return (
    <form
      id={props.form}
      onSubmit={props.onSubmit}
    >
      <FormRequiredFields />
      <FieldWrapper>
        <label htmlFor="id-name">Name <FormRequiredField /></label>
        <input
          id="id-name"
          name="name"
          type="text"
          value={props.name}
          onChange={props.handleNameChange}
          required
          autoFocus
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormErrors
          error={props.error}
        />
      </FieldWrapper>

    </form>
  )
}


CreateFolderForm.propTypes = {
  form: PropTypes.string.isRequired,
  name: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleNameChange: PropTypes.func.isRequired,
}

export default CreateFolderForm
