import React, { useState } from 'react'
import {
  useQuery,
  gql,
} from '@apollo/client'

import UserMenu from '../userMenu/UserMenu'
import defaultAvatar from '../userProfile/default_avatar.png'

import styles from './userMenuButton.module.css'

const USER_MENU_QUERY = gql`
  query UserMenuQuery {
    me {
      id
      username
      firstName
      lastName
      avatar(minSize: 32, format: "jpeg")
    }
  }
`

export function getUserNames(user) {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`
  }
  return getUserShortName(user)
}

export function getUserShortName(user) {
  if (user.firstName) return user.firstName
  return user.username
}


function UserMenuButton() {
  const [ menu, setMenu ] = useState(false)

  const { loading, data, error } = useQuery(USER_MENU_QUERY)

  if (loading) return null
  if (error) console.error(error)

  function getMenu() {
    if (! menu) return null
    return (
      <UserMenu
        close={() => setMenu(false)}
        username={data.me.username}
      />
    )
  }

  return (
    <div className={styles.wrapper}>
      <button
        className={styles.userMenu}
        onClick={() => setMenu(! menu)}
      ><img
        src={data.me.avatar || defaultAvatar}
        alt={`${data.me.username}'s avatar`}
        
    /><div className={styles.userNames}>{getUserNames(data.me)}</div>
      </button>
        {getMenu()}
    </div>
  )
}


export default UserMenuButton
