
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import LoadingMore from '../loadingMore/LoadingMore'
import JustifiedGridPhotosList from '../justifiedGridPhotosList/JustifiedGridPhotosList'

function PhotosList(props) {
  //console.log('PhotosList', props)
  
  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop
    const scrollHeight = document.documentElement.scrollHeight
    const clientHeight = document.documentElement.clientHeight
    
    if (scrollTop + clientHeight >= scrollHeight) {
      if (props.hasNextPage) {
        props.loadMore()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })


  return (
    <section>
      <JustifiedGridPhotosList
        photos={props.photos}
      />
      <LoadingMore
        loadingMore={props.loadingMore}
      />
    </section>
  )
}

export default PhotosList


PhotosList.propTypes = {
  photos: PropTypes.array.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired,
}
