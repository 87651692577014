
import PropTypes from 'prop-types'
import AccessibilityText from '../accessibilityText/AccessibilityText'
import styles from './removeFromAlbumButton.module.css'

import {
  modalTypes,
  modalStatusVar,
  modalOptionsVar,
} from '../../modal'

function RemoveFromAlbumButton({ 
  active, getSelectedIDs, currentAlbumID, redirectTo, unselectAll
}) {
  if (! currentAlbumID) return null
  return (
    <div className={styles.wrapper}>
      <button
        className={active ? styles.removeButtonActive : styles.removeButton}
        title="Remove selection from current album"
        onClick={() => { 
          if (! active) return
          modalStatusVar(modalTypes.REMOVE_PHOTOS_FROM_ALBUM)
          modalOptionsVar({
            photosIDs: getSelectedIDs(),
            currentAlbumID, 
            redirectTo,
            unselectAll,
          })
        }}
      >
        <AccessibilityText text="Remove selection from current album" />
      </button>
    </div>
  )
}

export default RemoveFromAlbumButton

RemoveFromAlbumButton.propTypes = {
  active: PropTypes.bool.isRequired,
  getSelectedIDs: PropTypes.func.isRequired,
  currentAlbumID: PropTypes.string,
  redirectTo: PropTypes.string,
  unselectAll: PropTypes.func,
}
