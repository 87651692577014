
import PropTypes from 'prop-types'

import {
  gql,
  useQuery,
} from '@apollo/client'


import { IMAGE_PHOTO_FIELDS } from '../image/fragments'
import Image from '../image/Image'


const ALBUM_COVER_QUERY = gql`
${IMAGE_PHOTO_FIELDS}
query AlbumCoverQuery($coverID: ID!) {
  node(id: $coverID) {
    id
    __typename
    ... on Photo {
      ...ImagePhotoFields
    }
  }
}
`

function AlbumCover({ coverID, height, width, title, alt }) {
  const { loading, data, error } = useQuery(ALBUM_COVER_QUERY, {
    variables: { coverID: coverID },
  })

  if (loading) return null
  if (error) {
    console.error(error)
    return null
  }

  return (
    <Image
      photo={data.node}
      minWidth={width}
      minHeight={height}
      title={title}
      alt={alt}
    />
  )
      
}

export default AlbumCover

AlbumCover.propTypes = {
  coverID: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  title: PropTypes.string,
  alt: PropTypes.string,
}
