import { useQuery } from '@apollo/client'

import { Outlet, Navigate } from 'react-router-dom'

import { AUTHENTICATED_QUERY } from '../app/App'


function AuthenticationRequired() {
  
  const { loading, data, error } = useQuery(AUTHENTICATED_QUERY)

  if (loading) return null
  if (error) console.error(error)

  if (data && data.me) {
    return (<Outlet />)
  }

  return (<Navigate to="/login/" replace={true} />)
}


export default AuthenticationRequired
