import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import FieldWrapper from '../fieldWrapper/FieldWrapper'
import Submit from '../submit/Submit'
import SignupForm from '../signupForm/SignupForm'
import Spinner from '../spinner/Spinner'
import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import RedirectHomeIfAuthenticated from '../redirectHomeIfAuthenticated/RedirectHomeIfAuthenticated'

const SIGNUP_FORM = 'SIGNUP_FORM'


const SIGNUP_MUTATION = gql`
mutation AddUser($email: String!, $password: String!, $username: String!) {
  addUser(email: $email, password: $password, username: $username) {
    __typename
    id
    email
    username
    lastName
    firstName
  }
}
`
function Signup() {
  const [email, setEmail] = useState('')
  const [localErrors, setLocalErrors] = useState(null)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const navigate = useNavigate()
  const [signup, { loading, error, client }] = useMutation(SIGNUP_MUTATION, {
    onCompleted: (data) => {
      //console.log('signup', data)
      // We reset store
      client.resetStore()
      // We redirect to user home page after success signup
      navigate('/')
    },
    onError: (e) => { console.error(e) },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    // we reset local errors
    setLocalErrors(null)
    let localErrors = []
    if (password !== passwordConfirm) {
      localErrors.push("Password and password confirmation must match!")
    }
    const re = /^[0-9A-Za-z-]+$/
    if (! re.test(username)) {
      localErrors.push("Invalid characters in username.")
    }
    if (localErrors.length === 0) {
      signup({ variables: { email, password, username } })
    } else { setLocalErrors(localErrors) }
  }
    
  return (
    <RedirectHomeIfAuthenticated>
      <ModalSection>
        <ModalHeader
          title="Join us"
        />
        <ModalContent>
          <SignupForm
            form={SIGNUP_FORM}
            onSubmit={handleSubmit}
            handleEmailChange={(e) => setEmail(e.target.value)}
            handleUsernameChange={(e) => setUsername(e.target.value)}
            handlePasswordChange={(e) => setPassword(e.target.value)}
            handlePasswordConfirmChange={(e) => setPasswordConfirm(e.target.value)}
            email={email}
            username={username}
            password={password}
            passwordConfirm={passwordConfirm}
            error={error}
            localErrors={localErrors}
          />
        </ModalContent>
        <ModalFooter>
          {loading && <Spinner message="Signing up..." />}
          <FieldWrapper>
            <Submit
              primary={true}
              form={SIGNUP_FORM}
              value="Sign up"
            />
          </FieldWrapper>
          <p>Already have an account? <Link to="/login">Log in</Link></p>
        </ModalFooter>
      </ModalSection>
    </RedirectHomeIfAuthenticated>
  )
}

export default Signup
