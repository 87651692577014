import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import {
  modalTypes,
  modalStatusVar,
} from '../../modal'

import styles from './userMenu.module.css'

function UserMenu(props) {
  return (
    <div
      className={styles.overlay}
      onClick={props.close}
    >
      <ul>
        <li><Link to="/">Library</Link></li>
        <li><Link to="/all/">All Photos</Link></li>
        <hr />
        <li><button
          onClick={() => {
            modalStatusVar(modalTypes.ADD_PHOTOS)
            props.close()
          }}
        >Add photos</button></li>
        <hr />
        <li><Link
          to="/profile/"
        >Profile</Link></li>
        <hr />
        <li><button
          onClick={() => {
            modalStatusVar(modalTypes.LOGOUT)
            props.close()
          }}
        >Sign out</button></li>
      </ul>
    </div>
  )
}

UserMenu.propTypes = {
  close: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
}

export default UserMenu
