import {
  useQuery,
  gql,
} from '@apollo/client'
import { useParams } from 'react-router-dom'

import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'
import FolderDetail from '../folderDetail/FolderDetail'
import AlbumDetail from '../albumDetail/AlbumDetail'

const ALBUM_OR_FOLDER_QUERY = gql`
  query AlbumOrFolderQuery($id: ID!) {
    node(id: $id) {
      id
      __typename
    }
  }
`

function AlbumOrFolderDetail() {
  let { albumID } = useParams()

  //console.log('AlbumOrFolderDetail id', id)

  const { loading, data, error } = useQuery(ALBUM_OR_FOLDER_QUERY, {
    variables: { id: albumID },
  })

  if (loading) return <Spinner />
  if (error) {
    return <ErrorMessage error={error} />
  }
  //console.log('AlbumOrFolderDetail typename', data.node.__typename)

  if (data.node.__typename === 'Folder') return (<FolderDetail folderID={albumID} />)
  if (data.node.__typename === 'Album') return (<AlbumDetail albumID={albumID} />)

  return <ErrorMessage />
}

export default AlbumOrFolderDetail
