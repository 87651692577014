import PropTypes from 'prop-types'
import styles from '../submit/submit.module.css'


function getClassNames(props) {
  let classes = []

  // we add extra classes
  classes.push(styles.button)
  if (props.primary) { classes.push(styles.primary) }
  if (props.className) { classes.push(props.className) }

  return classes.join(" ")
}

function Button(props) {
  return (
    <button
      className={getClassNames(props)}
      type={props.type || "button"}
      title={props.title || null}
      value={props.value || ""}
      disabled={props.disabled || null}
      onClick={props.onClick}
    >{props.children}</button>
  )
}

Button.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  primary: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default Button

