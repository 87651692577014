import {
  useQuery,
  gql,
  useReactiveVar,
} from '@apollo/client'
import {
  Link,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import { filterVar } from '../headerSearch/HeaderSearch'
import { IMAGE_PHOTO_FIELDS } from '../image/fragments'

import useKeyboardNavigation from '../../hooks/useKeyboardNavigation'

import Image from '../image/Image'
import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'
import NavigationBar from '../navigationBar/NavigationBar'
import DeletePhotosButton from '../deletePhotosButton/DeletePhotosButton'
import AddToAlbumButton from '../addToAlbumButton/AddToAlbumButton'
import RemoveFromAlbumButton from '../removeFromAlbumButton/RemoveFromAlbumButton'
import AlbumCoverButton from '../albumCoverButton/AlbumCoverButton'
import AccessibilityText from '../accessibilityText/AccessibilityText'
import PhotoInfo from '../photoInfo/PhotoInfo'


import styles from './photoDetail.module.css'


const ALBUM_PHOTO_DETAIL_QUERY = gql`
  ${IMAGE_PHOTO_FIELDS}
  query AlbumPhotoDetailQuery(
    $photoID: ID!,
    $albumID: ID!,
  ) {
    node(id: $albumID) {
      id
      __typename
      ... on Album {
        photo(id: $photoID) {
          nextID
          prevID
          node {
            ...ImagePhotoFields
          }
        }
      }
    }
  }
`

const USER_PHOTO_DETAIL_QUERY = gql`
  ${IMAGE_PHOTO_FIELDS}
  query UserPhotoDetailQuery($photoID: ID!, $search: String) {
    me {
      id
      photo(id: $photoID, search: $search) {
        nextID
        prevID
        node {
          ...ImagePhotoFields
        }
      }
    }
  }
`




function PhotoDetail() {
  let { photoID, albumID } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const filter = useReactiveVar(filterVar)

  const navigateNext = () => {
    if (edge.nextID) {
      navigate(getBaseLink(edge.nextID))
    }
  }
  
  const navigatePrev = () => {
    if (edge.prevID) {
      navigate(getBaseLink(edge.prevID))
    }
  }

  const navigateBack = () => {
    if (backlink) navigate(backlink)
  }

  
  useKeyboardNavigation(navigateNext, navigatePrev, navigateBack)

  const getBaseLink = (photoID) => {
    if (albumID) {
      return `/albums/${albumID}/photo/${photoID}/`
    }
    return `/all/photo/${photoID}/`
  }
  
  const QUERY = albumID ? ALBUM_PHOTO_DETAIL_QUERY : USER_PHOTO_DETAIL_QUERY
  
  const { loading, data, error } = useQuery(QUERY, {
    variables: {
      photoID,
      albumID,
      search: filter,
    },
  })

  const backlink = pathname.split('photo')[0]

  if (loading) return (
    <section id="navigationContent">
      <NavigationBar
        title={""}
        backlink={backlink}
        backlinkTitle="back"
    ></NavigationBar>
      <Spinner />
    </section>
  )
  if (error) {
    return <ErrorMessage error={error} />
  }

  //console.log('PhotoDetail', data)
  let edge = albumID ? data.node.photo : data.me.photo

  return (
    <section id="navigationContent">
      <NavigationBar
        title={edge.node.originalName}
        backlink={backlink}
        backlinkTitle="back"
      >
        <AlbumCoverButton
          currentPhotoID={photoID}
          currentAlbumID={albumID}
        />
        <AddToAlbumButton
          active={true}
          getSelectedIDs={() => [photoID]}
          currentAlbumID={albumID}
        />
        <RemoveFromAlbumButton
          active={true}
          getSelectedIDs={() => [photoID]}
          currentAlbumID={albumID}
          redirectTo={backlink}
        />
        <DeletePhotosButton
          active={true}
          getSelectedIDs={() => [photoID]}
          redirectTo={backlink}
        />
      </NavigationBar>
      <div className={styles.mainWrapper}>
        {edge.prevID ? (<Link
          className={styles.prev}
          to={getBaseLink(edge.prevID)}
          title="Previous Photo"
        ><AccessibilityText text="Previous photo" /></Link>) : null}
        {edge.nextID ? (<Link
          className={styles.next}
          to={getBaseLink(edge.nextID)}
          title="Next Photo"
        ><AccessibilityText text="Next photo" /></Link>) : null}
        <Link
          className={styles.lightboxLink}
          title="Open in lightbox"
          to={"lightbox/"}
        >
          <Image
            photo={edge.node}
            className={styles.image}
            minWidth={2048}
          />
        </Link>
     </div>
     <PhotoInfo photoID={edge.node.id} />
    </section>
  )
}


export default PhotoDetail


