
import PropTypes from 'prop-types'
import AccessibilityText from '../accessibilityText/AccessibilityText'
import styles from './addToAlbumButton.module.css'

import {
  modalTypes,
  modalStatusVar,
  modalOptionsVar,
} from '../../modal'


function AddToAlbumButton({ active, getSelectedIDs, currentAlbumID }) {
  return (
    <div className={styles.wrapper}>
      <button
        className={active ? styles.addButtonActive : styles.addButton}
        title="Add selection to an album"
        onClick={() => { 
          if (! active) return
          modalStatusVar(modalTypes.ADD_PHOTOS_TO_ALBUM)
          modalOptionsVar({
            photosIDs: getSelectedIDs(),
            currentAlbumID, 
          })
          //console.log('add to album!')
        }}
      >
        <AccessibilityText text="Add selection to an album" />
      </button>
    </div>
  )
}

export default AddToAlbumButton

AddToAlbumButton.propTypes = {
  active: PropTypes.bool.isRequired,
  getSelectedIDs: PropTypes.func.isRequired,
  currentAlbumID: PropTypes.string,
}
