import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function listsHaveCommon(list1, list2) {
  /*
   * Returns true if one element of list1 is also in list2
   * false otherwise
   */
  for (let i=0, l=list2.length; i < l; i++) {
    if (list1.indexOf(list2[i]) > -1) return true
  }
  return false
}


export function formatFileSize(bytes, precision = 1) {
  if (isNaN(bytes) || ! isFinite(bytes)) return '-'
  let units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
  let number = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + units[number]
}



// A custom hook that builds on useLocation to parse
// the query string for you.
// from https://v5.reactrouter.com/web/example/query-parameters
export function useQueryString() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}
