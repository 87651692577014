import {
  gql,
} from '@apollo/client'


export const IMAGE_PHOTO_FIELDS = gql`
fragment ImagePhotoFields on Photo {
  id
  title
  originalName
  sha1
  smallSecret
  largeSecret
  storageId
  height
  width
  dominantColor
  previewsFormats
  previewsSizes
}
`
