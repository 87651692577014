import PropTypes from 'prop-types'

import styles from './managePhotosBar.module.css'

function ManagePhotosBar({ selected, done, children }) {
  //console.log('managePhotosBar')
  return (
    <nav className={styles.bar}>
      <div className={styles.count}>{selected.length} Selected</div>
      { children }
      <button
        className={styles.done}
        onClick={done}
      >Done</button>
    </nav>
  )
}

export default ManagePhotosBar


ManagePhotosBar.propTypes = {
  selected: PropTypes.array.isRequired,
  done: PropTypes.func.isRequired,
}
