
import PropTypes from 'prop-types'
import {
  gql,
  useQuery,
  useMutation,
} from '@apollo/client'

import PhotoRate from '../photoRate/PhotoRate'
import PhotoAlbums from '../photoAlbums/PhotoAlbums'
import FormAutoSaveTextInput from '../formAutoSaveTextInput/FormAutoSaveTextInput'
import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'

import styles from './photoInfo.module.css'


const PHOTO_INFO_QUERY = gql`
  query PhotoInfoQuery($photoID: ID!) {
    node(id: $photoID) {
      id
      __typename
      ... on Photo {
        title
        caption
        rating
        height
        width
        weight
        createdAt
        originalName
        camera
        lens
        focalLength
        exposureTime
        aperture
        iso
        exifDate
      }
    }
 } 
`

const UPDATE_PHOTO_TITLE_MUTATION = gql`
mutation UpdatePhotoTitle($id: ID!, $title: String!) {
  updatePhoto(id: $id, title: $title) {
    __typename
    id
    title
  }
}
`

const UPDATE_PHOTO_CAPTION_MUTATION = gql`
mutation UpdatePhotoCaption($id: ID!, $caption: String!) {
  updatePhoto(id: $id, caption: $caption) {
    __typename
    id
    caption
  }
}
`




function humanSize(weight) {
  // convert weight in bytes to kylobytes, megabytes or gygabytes
  if (weight < 1000) return `${weight} B`
  if (weight < 1000000) return `${Math.round(weight * 0.001 * 100) / 100} KB`
  if (weight < 1000000000) return `${Math.round(weight * 0.000001 * 100) / 100} MB`
  return `${Math.round(weight * 0.000000001 * 100) / 100} GB`
}

function humanDate(unixTimestamp) {
  // convert unix timestamp in human readable date
  let d = new Date(+unixTimestamp) // convert string to int
  let date = d.toLocaleDateString([], {
    day: "numeric",
    month: "long",
    year: "numeric",
  })
  let time = d.toLocaleTimeString([], {
    hour: "2-digit", minute: "2-digit", second: "2-digit"
  })
  return date + ' at ' + time
}

function humanExposureTime(time) {
  // From 0,3s we count in seconds
  if (time >= 0.3) return time

  // Under we count in fraction of seconds
  return `1/${Math.round(1/time)}`
}

function Exposure({ exposureTime, aperture, iso }) {
  if (!exposureTime && !aperture && !iso) {
    return (<div className={styles.infoGroup + ' ' + styles.exposure}><em>No exposure information</em></div>)
  }
  return (
    <div className={styles.infoGroup + ' ' + styles.exposure}>
      {exposureTime ? (<span>{humanExposureTime(exposureTime)} sec</span>) : null}
      {aperture ? (<span><span className={styles.fnum}>{"\u{1D453}"}</span>/{aperture}</span>) : null}
      {iso ? (<span>ISO {iso}</span>) : null}
    </div>
  )
}

function CameraAndSize({ camera, lens, focalLength, width, height, weight }) {
  return (
    <div className={styles.infoGroup}>
      <div>{camera || (<em>No camera information</em>)}</div>
      <div>{lens || (<em>No lens information</em>)}
      {focalLength ? ' (' + focalLength + ' mm)' : ""}</div>
      <div>{width} x {height} px{"\u2002\u2022\u2002"}{humanSize(weight)}</div>
    </div>
  )
}

function NameAndDate({ name, imported, taken }) {
  return (
    <div className={styles.infoGroup}>
      <div>{name}</div>
      <div>{taken ? humanDate(taken) : (<em>unknown</em>)} (taken)</div>
      <div>{humanDate(imported)} (imported)</div>
    </div>
  )
}

function PhotoInfo({ photoID }) {

  const { loading, data, error } = useQuery(PHOTO_INFO_QUERY, {
    variables: { photoID }
  })

  const [updateCaption] = useMutation(UPDATE_PHOTO_CAPTION_MUTATION, {
    onError: (e) => { console.error(e) },
  })

  const [updateTitle] = useMutation(UPDATE_PHOTO_TITLE_MUTATION, {
    onError: (e) => { console.error(e) },
  })


  const submitCaption = (caption) => {
    updateCaption({variables: { id: photoID, caption }})
  }

  const submitTitle = (title) => {
    updateTitle({variables: { id: photoID, title }})
  }

  

  if (loading) return <Spinner />
  if (error) {
    return <ErrorMessage error={error} />
  }
  
  const photo = data.node

  return (
    <section className={styles.photoInfo}>
      <div className={styles.wrapper}>
        <PhotoRate id={photo.id} rating={photo.rating} />
        <div className={styles.formWrapper}>
          <FormAutoSaveTextInput
            name="title"
            label="Title"
            initialValue={photo.title}
            placeholder="No title"
            submit={submitTitle}
            className={styles.form}
          />
          <FormAutoSaveTextInput
            name="caption"
            label="Caption"
            initialValue={photo.caption}
            placeholder="No caption"
            submit={submitCaption}
            className={styles.form}
          />
        </div>
        <Exposure 
          exposureTime={photo.exposureTime}
          aperture={photo.aperture}
          iso={photo.iso}
        />
        <CameraAndSize
          camera={photo.camera}
          lens={photo.lens}
          focalLength={photo.focalLength}
          width={photo.width}
          height={photo.height}
          weight={photo.weight}
        />
        <NameAndDate
          name={photo.originalName}
          imported={photo.createdAt}
          taken={photo.exifDate}
        />
        <PhotoAlbums photoID={photo.id} />
      </div>
    </section>
  )
}

export default PhotoInfo


PhotoInfo.propTypes = {
  photoID: PropTypes.string.isRequired,
}
