import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import withModal from '../../hoc/withModal/withModal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import Button from '../button/Button'
import Spinner from '../spinner/Spinner'
import { 
  removeItemFromEdges,
} from '../../helpers/GraphqlHelpers'
import { ALBUM_QUERY } from '../renameAlbum/RenameAlbum'



const DELETE_ALBUM_MUTATION = gql`
mutation DeleteAlbum($id: ID!) {
  deleteAlbum(id: $id) {
    id
    folder { id }
    user { id }
  }
}
`

function DeleteAlbum(props) {

  const navigate = useNavigate()
  
  let id = props.modalOptions && props.modalOptions.albumID ?
    props.modalOptions.albumID : null

  const getAlbumStatus = useQuery(ALBUM_QUERY, {
    variables: { id },
  })

  const [deleteAlbum, { loading, error }] = useMutation(DELETE_ALBUM_MUTATION, {
    variables: { id },
    update: (cache, { data: { deleteAlbum }}) => {

      function removeAlbumFromCache(cachedAlbums, { readField }) {
        return removeItemFromEdges(cachedAlbums, readField, deleteAlbum.id)
      }

      // we get parent folder (user if it's root, folder otherwise)
      let folder = deleteAlbum.folder ? deleteAlbum.folder : deleteAlbum.user

      // We remove album from it's parent folder
      cache.modify({
        id: cache.identify(folder),
        fields: {
          albums: removeAlbumFromCache,
        }
      })
    },
    onCompleted: (data) => {
      let backlink = "/"
      if (data.deleteAlbum.folder) {
        backlink = `/albums/${data.deleteAlbum.folder.id}/`
      }
      //console.log('deleted album', data)
      props.closeModal()
      // we redirect to parent folder
      navigate(backlink)
    },
    onError: (e) => console.error(e),
  })

  //console.log('DeleteAlbum', props)

  if (getAlbumStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getAlbumStatus.error) {
    console.error(getAlbumStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title="Delete an album"
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured loading initial data...</p>
        </ModalContent>
      </ModalSection>
    )
  }  

  if (error) {
    console.error(getAlbumStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title="Delete an album"
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured deleting...</p>
        </ModalContent>
      </ModalSection>
    )
  }

  let data = getAlbumStatus.data.node


  return (
    <ModalSection>
      <ModalHeader
        title="Delete an album"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <h6>Are you sure you want to delete album <em>"{data.title}"</em> ?</h6>
        <p>Album will be deleted, but not photos it contains.</p>
        <p><em>(this operation is irreversible)</em></p>
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Deleting album..." />}
        <Button
          onClick={deleteAlbum}
          primary={true}
        >Delete album</Button>
      </ModalFooter>
    </ModalSection>
  )
}



DeleteAlbum.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}

export default withModal(DeleteAlbum)







