import PropTypes from 'prop-types'
import React, { useState } from 'react'

import AccessibilityText from '../accessibilityText/AccessibilityText'
import NavigationMenu from '../navigationMenu/NavigationMenu'

import styles from './folderCreateButton.module.css'

import {
  modalTypes,
  modalStatusVar,
  modalOptionsVar,
} from '../../modal'


function FolderCreateButton({ folderID }) {
  const [ menu, setMenu ] = useState(false)
  function getMenu() {
    if (! menu) return null
    return (
      <NavigationMenu>
        <ul>
          <li><button
            onClick={() => {
              modalStatusVar(modalTypes.CREATE_FOLDER)
              modalOptionsVar({ folderID })
              setMenu(false)
            }}
          >Create a folder</button></li>
          <hr />
          <li><button
            onClick={() => {
              modalStatusVar(modalTypes.CREATE_ALBUM)
              modalOptionsVar({ folderID })
              setMenu(false)
            }}
          >Create an album</button></li>
        </ul>
      </NavigationMenu>
    )
  }
  return (
    <div className={styles.wrapper}>
      <button
        className={styles.plusButton}
        onClick={() => { 
          setMenu(! menu)
        }}
      >
          <AccessibilityText text="Create a folder or an album" />
      </button>
      {getMenu()}
    </div>
  )
}

export default FolderCreateButton

FolderCreateButton.propTypes = {
  folderID: PropTypes.string
}



