import PropTypes from 'prop-types'

import FormRequiredField from '../formRequiredField/FormRequiredField'
import FormRequiredFields from '../formRequiredFields/FormRequiredFields'
import FieldWrapper from '../fieldWrapper/FieldWrapper'
import FormErrors from '../formErrors/FormErrors'

function CreateAlbumForm(props) {
  return (
    <form
      id={props.form}
      onSubmit={props.onSubmit}
    >
      <FormRequiredFields />
      <FieldWrapper>
        <label htmlFor="id-title">Title <FormRequiredField /></label>
        <input
          id="id-title"
          name="title"
          type="text"
          value={props.title}
          onChange={props.handleTitleChange}
          required
          autoFocus
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormErrors
          error={props.error}
        />
      </FieldWrapper>

    </form>
  )
}


CreateAlbumForm.propTypes = {
  form: PropTypes.string.isRequired,
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
}


export default CreateAlbumForm
