import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useMutation, useQuery } from '@apollo/client'

import withModal from '../../hoc/withModal/withModal'
import { FOLDER_QUERY, UPDATE_FOLDER_MUTATION } from '../renameFolder/RenameFolder'
import { NEW_FOLDER_FRAGMENT } from '../createFolder/CreateFolder'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalFooter from '../modalFooter/ModalFooter'
import Button from '../button/Button'
import Spinner from '../spinner/Spinner'
import FormNavigation from '../formNavigation/FormNavigation'
import { 
  createItemRef,
  addItemToSortedByStringEdges,
  removeItemFromEdges,
} from '../../helpers/GraphqlHelpers'






function MoveFolder(props) {
  const [originalParent, setOriginalParent] = useState(null)
  const [name, setName] = useState('')
  const [parent, setParent] = useState(null)

  let id = props.modalOptions && props.modalOptions.folderID ?
    props.modalOptions.folderID : null

  const getFolderStatus = useQuery(FOLDER_QUERY, {
    variables: { id },
    onCompleted: ({ node }) => {
      // We populate state with initial data
      const parentID = node.parent ? node.parent.id : null
      setName(node.name || '')
      setOriginalParent(parentID)
      setParent(parentID)
    }
  })

  const [moveFolder, { loading }] = useMutation(UPDATE_FOLDER_MUTATION, {
    update: (cache, { data: { updateFolder }}) => {
      const updatedFolderRef = createItemRef(cache, updateFolder, NEW_FOLDER_FRAGMENT)

      function addFolderToCache(cachedFolders, { readField }) {
        return addItemToSortedByStringEdges(cachedFolders, readField,
          updatedFolderRef, 'name')
      }

      function removeFolderFromCache(cachedFolders, { readField }) {
        return removeItemFromEdges(cachedFolders, readField, updateFolder.id)
      }
      
      // We remove folder from old parent (user if it's root, other folder otherwise)
      let originalParentObject = originalParent ? getFolderStatus.data.node.parent :
        updateFolder.user
      cache.modify({
        id: cache.identify(originalParentObject),
        fields: {
          folders: removeFolderFromCache,
        }
      })

      // We add folder to new parent (user if it's root, other folder otherwise)
      let parentObject = parent ? updateFolder.parent : updateFolder.user
      cache.modify({
        id: cache.identify(parentObject),
        fields: {
          folders: addFolderToCache,
        }
      })
    },
    onCompleted: (data) => {
      //console.log('updated folder', data)
      props.closeModal()
    },
    onError: (e) => console.error(e),
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    moveFolder({ variables: {
      id, name, parent,
    }})
  }

  if (getFolderStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getFolderStatus.error) {
    console.error(getFolderStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <p>Sorry, an error occured loading initial data...</p>
      </ModalSection>
    )
  }

  //console.log('MoveFolder', props)
  return (
    <ModalSection>
      <ModalHeader
        title={"Move folder to"}
        close={props.closeModal}
        closeTitle="Cancel"
      />
        <FormNavigation
          parentID={parent}
          setParentID={setParent}
          editedID={id}
          disabled={parent === originalParent}
        />
      <ModalFooter>
        {loading && <Spinner message="Moving folder..." />}
        <Button
          primary={true}
          disabled={parent === originalParent}
          onClick={handleSubmit}
        >Move folder</Button>
      </ModalFooter>
    </ModalSection>
  )
}

MoveFolder.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    folderID: PropTypes.string,
  }).isRequired,
}


export default withModal(MoveFolder)


