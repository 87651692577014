import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import withModal from '../../hoc/withModal/withModal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import Button from '../button/Button'
import Spinner from '../spinner/Spinner'
import { 
  removeItemFromEdges,
} from '../../helpers/GraphqlHelpers'


const REMOVE_PHOTO_FROM_ALBUM_MUTATION = gql`
mutation RemovePhotoFromAlbum($photo: ID!, $album: ID!) {
  removePhotoFromAlbum(photo: $photo, album: $album) {
    id
    node { id }
  }
}
`

function RemovePhotosFromAlbum(props) {
  const navigate = useNavigate()

  const { currentAlbumID, photosIDs, redirectTo, unselectAll } = props.modalOptions


  const [removePhotoFromAlbum, { loading }] = useMutation(REMOVE_PHOTO_FROM_ALBUM_MUTATION, {
    update: (cache, { data: { removePhotoFromAlbum }}) => {

      function removePhotoEdgeFromCache(cachedEdges, { readField }) {
        return removeItemFromEdges(cachedEdges, readField, removePhotoFromAlbum.id)
      }

      // We remove photoEdge from it's album
      cache.modify({
        id: `Album:${currentAlbumID}`,
        fields: {
          photos: removePhotoEdgeFromCache,
          // we delete single photos, because prev / next ids are not good anymore
          photo(cachedPhoto, { DELETE }) {
            // No idea why, it doesn't work with INVALIDATE sentinel.
            return DELETE
          }
        }
      })

      const data = cache.readQuery({
        query: gql`
          query AlbumCoverQuery($id: ID!) {
            node(id: $id) {
              id
              cover
            }
          }
        `,
        variables: {
          id: currentAlbumID,
        }
      })
      if (data && photosIDs.includes(data.node.cover)) {
        // If album's cover's photo is about to be deleted,
        // we delete album's field
        cache.modify({
          id: `Album:${currentAlbumID}`,
          fields: {
            cover(existingCover, { DELETE }) {
              return DELETE;
            }
          }
        })
      }
    },
    onCompleted: (data) => {
      //console.log('Removed from album', data)
      props.closeModal()
    },
    onError: (e) => console.error(e),
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    let album = currentAlbumID
    for (let i=0; i < photosIDs.length; i++) {
      removePhotoFromAlbum({variables: {
        photo: photosIDs[i],
        album: album,
      }})
    }
    if (unselectAll) unselectAll()
    if (redirectTo) {
      navigate(redirectTo)
    }
  }

  //console.log('RemovePhotosFromAlbum', props)


  return (
    <ModalSection>
      <ModalHeader
        title="Remove from album"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <h6>Are you sure you want to remove {photosIDs.length} photo{photosIDs.length > 1 ? "s" : ""} from current album?</h6>
        {photosIDs.length > 1 ? 
            (<p><em>Those photos won't be deleted, you can still find them in "All Photos".</em></p>)
          : 
            (<p><em>This photo won't be deleted, you can still find it in "All Photos".</em></p>)
        }
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Removing photos..." />}
        <Button
          onClick={handleSubmit}
          primary={true}
        >Remove</Button>
      </ModalFooter>
    </ModalSection>
  )
}



RemovePhotosFromAlbum.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    currentAlbumID: PropTypes.string.isRequired,
    photosIDs: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
    redirectTo: PropTypes.string,
    unselectAll: PropTypes.func,
  })
}

export default withModal(RemovePhotosFromAlbum)



