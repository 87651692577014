import { useQuery } from '@apollo/client'
import { Navigate } from 'react-router-dom'

import { AUTHENTICATED_QUERY } from '../app/App'


function RedirectHomeIfAuthenticated({ children }) {
  
  const { loading, data, error } = useQuery(AUTHENTICATED_QUERY)  
  
  if (loading) return null
  if (error) console.error(error)

  if (data.me && data.me.username) {
    return (<Navigate to="/" />)
  }

  return children

}


export default RedirectHomeIfAuthenticated
